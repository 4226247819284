import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';
import classNames from 'classnames';
import ReactCountryFlag from 'react-country-flag';
import { IntlContext } from 'src/utility/context/Internationalization';
import { FormattedMessage } from 'react-intl';
import { useScroll } from 'src/context/ScrollContext';
export default function NavbarHomeGuest (props) {
  const [langDropdown, setLangDropDown] = useState(false);
  const [serviceDropdown, setServiceDropDown] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { scrollToSection } = useScroll();
  const REACT_APP_ENV = process.env.REACT_APP_ENV;

  const handleLangDropdown = () => {
    setLangDropDown(!langDropdown);
  };

  const handleServiceDropdown = () => {
    setServiceDropDown(!serviceDropdown);
  };

  const handleClickOurService = () => {
    if (location.pathname === '/home') {
      scrollToSection('homeServices');
    } else {
      history.push('/home?section=homeServices');
      // scrollToSection('homeServices');
    }
  };
  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <NavItem>
        <NavLink className="nav-link-search py-0">
          <Button
            className={classNames('px-1 font-weight-bold font-11', {
              active: location?.pathname === '/home'
            })}
            color={location?.pathname === '/home' ? 'black' : 'flat-black'}
            onClick={() => history.push('/home')}
          >
            <FormattedMessage id="Home"/>
          </Button>
        </NavLink>
      </NavItem>
      <Dropdown
        tag="li"
        className="nav-item"
        isOpen={serviceDropdown}
        toggle={handleServiceDropdown}
        data-tour="language"
      >
        <DropdownToggle
          color="info"
          tag="a"
          className="nav-link p-0"
        >
          <Button
            className="px-1 font-weight-bold font-11"
            color={location?.pathname === '/ep-assure' ? 'black' : 'flat-black'}
          >
            <FormattedMessage id="Our Services"/>
          </Button>
        </DropdownToggle>
        <DropdownMenu right className="mt-0 our-services-menu" style={{top: '5px'}}>
          <DropdownItem
            className="font-weight-bold font-11"
            tag="a"
            onClick={handleClickOurService}
          >
            <FormattedMessage id="KYC & Due Diligence"/>
          </DropdownItem>
          <DropdownItem
            className="font-weight-bold font-11"
            tag="a"
            onClick={() => history.push('/ep-assure')}
          >
            <FormattedMessage id="EP-ASSURE"/>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <NavItem>
        <NavLink className="nav-link-search py-0">
          <Button
            className="px-1 font-weight-bold font-11"
            color={location?.pathname === '/contact-us' ? 'black' : 'flat-black'}
            onClick={() => history.push('/contact-us')}
          >
            <FormattedMessage id="Contact Us"/>
          </Button>
        </NavLink>
      </NavItem>
      {REACT_APP_ENV !== 'production' && (
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              'en' : 'English',
              'de' : 'German',
              'fr' : 'French',
              'pt' : 'Portuguese',
              'zh': '中文',
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={langDropdown}
                toggle={handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  color="info"
                  tag="a"
                  className="nav-link py-0"
                >
                  <Button
                    className="px-1 font-weight-bold font-11"
                    color="flat-info"
                  >
                    <ReactCountryFlag
                    className="country-flag"
                      countryCode={
                        context.locale === 'en'
                          ? 'us'
                          : context.locale === 'zh'
                          ? 'cn'
                          : context.locale
                      }
                      svg
                    />
                    <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                      {langArr[context.locale]}
                    </span>
                  </Button>
                </DropdownToggle>
                <DropdownMenu right className="mt-0 our-services-menu" style={{top: '5px'}}>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage('en')}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage('zh')}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="cn" svg />
                    <span className="ml-1">中文</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer>
      )}
      <NavItem className="border-left">
        <NavLink className="nav-link-search py-0">
          <Button
            className="px-1 font-weight-bold font-11"
            color={location?.pathname === '/register' ? 'info' : 'flat-info'}
            onClick={() => history.push('/register')}
          >
            <FormattedMessage id="Register"/>
          </Button>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link-search py-0">
          <Button
            className="px-1 font-weight-bold font-11"
            color={location?.pathname === '/login' ? 'info' : 'flat-info'}
            onClick={() => history.push('/login')}
          >
            <FormattedMessage id="Login"/>
          </Button>
        </NavLink>
      </NavItem>
    </ul>
  )
}

