import React, { createContext, useContext, useRef } from 'react';

// Create Scroll Context
const ScrollContext = createContext();

// Custom hook to use scroll context
export const useScroll = () => useContext(ScrollContext);

// ScrollProvider to wrap around layout component
export const ScrollProvider = ({ children }) => {
  const homeServiceSectionRef = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection = (section) => {
    if (section === 'homeServices' && homeServiceSectionRef.current) {
      homeServiceSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'section2' && section2Ref.current) {
      section2Ref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'section3' && section3Ref.current) {
      section3Ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ScrollContext.Provider value={{ scrollToSection, homeServiceSectionRef, section2Ref, section3Ref }}>
      {children}
    </ScrollContext.Provider>
  );
};
