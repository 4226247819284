/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    GET_CATEGORIES,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY
} from 'src/redux/actions/categoryActions';

const initialState = {
    categories: null,
    category: null
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) { 

    case GET_CATEGORIES: {
      const categories = action.payload
      return produce(state, (draft) => {
        draft.categories = categories
        draft.category = null
      })
    }

    case CREATE_CATEGORY: {
      const category = action.payload;

      return produce(state, (draft) => {
        draft.category = category;
      });
    }

    case UPDATE_CATEGORY: {
      const category = action.payload;
      return produce(state, (draft) => {
        draft.category = category;
      });
    }

    case DELETE_CATEGORY: {
      const category = action.payload;
      return produce(state, (draft) => {
        draft.category = category;
      });
    }

    default: {
      return state;
    }
  }
};

export default categoryReducer;
