import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_COMPANY = '@company/create-company';
export const DELETE_COMPANY = '@company/delete-company';
export const UPDATE_COMPANY = '@company/update-company';
export const GET_COMPANIES = '@company/list-company';
export const GET_LINKED_USERS = '@company/get-linked-users';
export const RESET_LINKED_USERS = '@company/reset-linked-users';

// export function getCompanies() {

//     const request = axios.get(`${API_URL}/api/companies`)

//     return (dispatch) => {
//         request.then((response) => dispatch({
//             type: GET_COMPANIES,
//             payload: response.data
//         }));
//     };
// }

export const getCompanies = (requestParams = null) => async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/api/companies`);
      if (response.status === 200) {
        dispatch({
          type: GET_COMPANIES,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getLinkedUsers = (companyId = null) => async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/api/companies/getLinkedUsers/${companyId}`);
      if (response.status === 200) {
        dispatch({
          type: GET_LINKED_USERS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  export const resetLinkedUsers = () => async (dispatch) => {
    dispatch({
        type: RESET_LINKED_USERS,
        payload: null,
    });
  };

export function createCompany(company) {

    const request = axios.post(`${API_URL}/api/companies`, company);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: CREATE_COMPANY,
            payload: response.data
        }));
    };
}

export function updateCompany(update) {
    const request = axios.put(API_URL + `/api/companies/${update._id}`, update);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_COMPANY,
            payload: response.data
        }));
    };
} 

export function deleteCompany(company) {
    // const request = axios.put(API_URL + `/api/companies/${update._id}`, update);
    const request = axios.delete(API_URL + `/api/companies/${company._id}`);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: DELETE_COMPANY,
            payload: company
        }));
    };
} 
