/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_EP_ORDER,
  DELETE_EP_ORDER,
  EP_ORDER_FAILURE,
  GET_EP_ORDERS,
  GET_EP_ORDER_BY_ID,
  SET_EP_PAYMENT,
  UPDATE_EP_ORDER,
  SET_STRIPE_KEY,
  SET_EP_LOADING,
} from 'src/redux/actions/epOrderActions';

const initialState = {
  epOrders: null,
  currentEpOrder: null,
  isEpOrderLoading: false,
  stripePublishKey: null,
};

const epOrderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {

    case SET_EP_LOADING: {
      const isLoading = action.payload;
      return produce(state, (draft) => {
        draft.isEpOrderLoading = isLoading;
      });
    }

    case EP_ORDER_FAILURE: {
      return produce(state, (draft) => {
        draft.isEpOrderLoading = false;
      });
    }

    case GET_EP_ORDERS: {
      return produce(state, (draft) => {
        draft.epOrders = payload.epOrders;
        draft.currentEpOrder = null;
        draft.isEpOrderLoading = false;
      })
    }

    case GET_EP_ORDER_BY_ID: {
      return produce(state, (draft) => {
        draft.currentEpOrder = payload.epOrder;
        draft.isEpOrderLoading = false;
      })
    }

    case CREATE_EP_ORDER: {
      return produce(state, (draft) => {
        const {
          newEpOrder,
          epOrders,
          // stripePublishKey,
        } = payload;
        draft.currentEpOrder = newEpOrder;
        draft.epOrders = epOrders;
        // draft.stripePublishKey = stripePublishKey;
        draft.isEpOrderLoading = false;
      });
    }
    
    case SET_STRIPE_KEY: {
      return produce(state, (draft) => {
        draft.stripePublishKey = payload;
        draft.isEpOrderLoading = false;
      });
    }
    
    case UPDATE_EP_ORDER: {
      const { epOrders, updatedEpOrder } = payload;
      return produce(state, (draft) => {
        draft.epOrders = epOrders;
        draft.currentEpOrder = updatedEpOrder;
        draft.isEpOrderLoading = false;
      });
    }

    case DELETE_EP_ORDER: {
      return produce(state, (draft) => {
        draft.epOrders = payload.epOrders;
        draft.isEpOrderLoading = false;
      });
    }

    case SET_EP_PAYMENT: {
      return produce(state, (draft) => {
        draft.paid = payload.paid;
      });
    }

    default: {
      return state;
    }
  }
};

export default epOrderReducer;
