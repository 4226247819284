import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import * as Icon from 'react-feather';
import ReactCountryFlag from 'react-country-flag';
import { history } from '../../../history';
import { IntlContext } from '../../../utility/context/Internationalization';
import authService from 'src/services/authService';
import { getItemFromStorage } from 'src/utils/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const UserDropdown = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleLogout = async () => {
    try {
      const preOrderId = getItemFromStorage('preorderid');
      authService.customerPreOrderLogout();
      history.push(`/customer/preorder/candidates?preorderid=${preOrderId}`);
      dispatch({
        type: 'RESET_STORE',
      });
    } catch (error) {
      enqueueSnackbar(intl?.formatMessage({id: 'Unable to logout'}), {
        variant: 'error'
      });
    }
  };
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={e => {
          e.preventDefault()
          handleLogout();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Log Out"/></span>
      </DropdownItem>
    </DropdownMenu>
  )
}

export default function NavbarPreOrderCustomer(props) {
  const [langDropdown, setLangDropDown] = useState(false);
  const handleLangDropdown = () => {
    setLangDropDown(!langDropdown);
  };
  const REACT_APP_ENV = process.env.REACT_APP_ENV;
  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      {REACT_APP_ENV !== 'production' && (
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              'en' : 'English',
              'de' : 'German',
              'fr' : 'French',
              'pt' : 'Portuguese',
              'zh': '中文',
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={langDropdown}
                toggle={handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                  className="country-flag"
                    countryCode={
                      context.locale === 'en'
                        ? 'us'
                        : context.locale === 'zh'
                        ? 'cn'
                        : context.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage('zh')}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="cn" svg />
                    <span className="ml-1">中文</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer>
      )}
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600 mb-0">
              {props.userName}
            </span>
          </div>
          <span data-tour="user" className="round bg-warning">
            <img
              src={props.userImg}
              className="round"
              height="40"
              width="40"
              alt="avatar"
            />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  )
}
