import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import logo from "src/assets/img/logo/linq-logo.png"

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
`;
const StyledImg = styled.img`
  width: 80px;
  max-width: 100%;
  margin-bottom: 10px;
`;

export default function SplashScreen() {

  return (
    <StyledDiv>
      <StyledImg src={logo} alt="logo" className="" />
      <Spinner color="info" />
    </StyledDiv>
  );
}

