import produce from 'immer';
import {
  GET_HANDSHAKE_BALANCE,
  GET_HANDSHAKE_DATA,
} from 'src/redux/actions/customSearchActions';

const initialState = {
  handshakeBalance: null,
  handshakeData: null,
};

const customSearchReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_HANDSHAKE_BALANCE: {
      const handshakeBalance = action.payload
      return produce(state, (draft) => {
        draft.handshakeBalance = handshakeBalance
      })
    }

    case GET_HANDSHAKE_DATA: {
      const handshakeData = action.payload;
      return produce(state, (draft) => {
        draft.handshakeData = handshakeData
      })
    }

    default: {
      return state;
    }
  }
};

export default customSearchReducer;
