import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  PreOrderCandidateStatusValues,
  PreOrderCandInfoStatusValues,
  PreOrderReportTypes,
} from './constants';

export const selectedValue = (options, value, comparewithValue = false) => {
  if (comparewithValue) {
    return options ? options.find((option) => option.value === value) : '';
  }
  return options ? options.find((option) => option.label === value) : '';
};
export const selectedMultiValue = (options, values, comparewithValue = false) => {
  if (comparewithValue) {
    return options ? options.filter((option) => values?.includes(option.value)) : '';
  }
  return options ? options.filter((option) => values?.includes(option.label)) : '';
};

export const getUserRoleLabel = (roleValue) => {
  let role = '';
  switch (roleValue) {
    case 'admin':
      role = 'Administrator';
      break;
    case 'partner':
      role = 'Team EXE';
      break;
    case 'reviewer':
      role = 'Ops Support';
      break;
    case 'customer':
      role = 'Customer';
      break;
  
    default:
      break;
  }
  return role;
};

export const applyPagination = (list, currentPage, perPage) => {
  return list.slice(currentPage * perPage, currentPage * perPage + perPage);
}

export const consentOptions = [
  {label: 'All Consent', value: 'all'},
  {label: 'Permitted and Overt (with consent)', value: 1},
  {label: 'Discreet and Covert (without consent)', value: 0},
];

export const bytesToSize =  (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getCountriesFromString = (countryString) => {
  const doNotSplit = ["Korea, Republic of", "People's Republic of China"];

  let modifiedStr = countryString;

  // Replace the commas in the phrases you don't want to split with a temporary placeholder
  doNotSplit.forEach(phrase => {
      const placeholder = phrase.replace(/,/g, '[COMMA]');
      modifiedStr = modifiedStr.replace(phrase, placeholder);
  });

  // Split the string
  let countries = modifiedStr.split(',');

  // Restore the original commas
  countries = countries.map((country) => {
    const countryWithComma = country.replace(/\[COMMA\]/g, ',');
    return countryWithComma.trim();
  });

  return countries;
};

export const getCountryCodes = (countries, countryNames) => {
  const filteredCountries = countries.filter((country) => 
    countryNames.includes(country.label)
  ).map((country) => ({ label: country.label, value: country.value }));
  return filteredCountries;
};

export const validateEmail = (email) => {
  if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return true;
  }
  console.log('You have entered an invalid email address!');
  return false;
}

export const formatContent = (text) => {
  // Replace '\t' with four non-breaking spaces
  let formattedText = text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  // Replace '\n' with <br />
  formattedText = formattedText.replace(/\n/g, '<br />');
  return formattedText;
};

export const getCurrentPreOrderCandidateStatus = (
  currentStatus,
  reportType = 'interim',
  releaseReportFiles = [],
  releaseReportType = 'interim',
  simple = false,
) => {
  let result = '';
  if(currentStatus) {
    switch (currentStatus) {
      case PreOrderCandidateStatusValues.draft:
        result = 'Draft';
        break;
      case PreOrderCandidateStatusValues.progress:
        result = 'In Progress';
        break;
      case PreOrderCandidateStatusValues.reportsubmitted:
        result = 'Report submitted';
        break;
      case PreOrderCandidateStatusValues.submit:
        result = 'Info Submitted for review';
        break;
      case PreOrderCandidateStatusValues.rejected:
        result = 'Resubmission required';
        break;
      case PreOrderCandidateStatusValues.reportrejected:
        result = 'Report resubmission required';
        break;
      case PreOrderCandidateStatusValues.completed:
        result = 'Completed';
        break;
    
      default:
        result = currentStatus;
        break;
    }
  }

  if (simple) return result;

  let reportTypeTemp = reportType;
  if (releaseReportFiles?.length > 0) {
    reportTypeTemp = releaseReportType;
  }
  if(result === 'Report submitted' || result === 'Report resubmission required') {
    if(reportTypeTemp === PreOrderReportTypes.interim) {
      result = 'Interim ' + result;   
    }else{
      result = 'Final ' + result;   
    }
  }
  if(result === 'Completed') {
    if(reportTypeTemp === PreOrderReportTypes.interim) {
      result = result + ' (Interim)';   
    }else{
      result = result + ' (Final)';   
    }
  }
  return result;
};

export const getCurrentPreORderCandidateInformationStatus = (candInfoStatus) => {
  let status = '';
  switch (candInfoStatus) {
    case PreOrderCandInfoStatusValues.sent:
      status = 'Sent';
      break;
    case PreOrderCandInfoStatusValues.respond:
      status = 'Candidate Responded';
      break;
    case PreOrderCandInfoStatusValues.reject:
      status = 'Submission Rejected';
      break;
    case PreOrderCandInfoStatusValues.draft:
      status = 'Draft';
      break;
  
    default:
      status = 'Sent'
      break;
  }
  return status;
};

export const getPreorderCandidateCnt = (candidates) => {
  let cntSubmit = 0, cntReject = 0, cntProgress = 0, cntReportsubmitted = 0, cntReportrejected = 0, cntCompleted = 0, cntInterim = 0, cntFinal = 0;
  candidates.forEach(candidate => {
    switch (candidate.currentStatus) {
      case 'submit':
        cntSubmit ++;
        break;
      case 'rejected':
        cntReject ++;
        break;
      case 'progress':
        cntProgress ++;
        break;
      case 'reportsubmitted':
        cntReportsubmitted ++;
        break;
      case 'reportrejected':
        cntReportrejected ++;
        break;
        case 'completed':
          if (candidate.releaseReportFiles?.length > 0) {
            if (candidate.releaseReportType === 'interim') {
              cntInterim ++;
            } else {
              cntFinal ++;
            }
            cntCompleted ++;
          } else {
            if(candidate.reportType === 'interim') cntInterim ++;
            if(candidate.reportType === 'final') cntFinal ++;
            cntCompleted ++;
          }
        break;
    
      default:
        break;
    }
  });
  return {
    cntSubmit,
    cntReject,
    cntProgress,
    cntReportrejected,
    cntReportsubmitted,
    cntCompleted,
    cntInterim,
    cntFinal,
  };
}

// Utility function to get item with expiry
export function getItemFromStorage(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  try {
    const item = JSON.parse(itemStr);
    const now = new Date();
  
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (error) {
    console.error('Error parsing JSON from localStorage', error);
    localStorage.removeItem(key); // Clean up invalid data
    return null;
  }
    
};

export function setItemWithExpiryToStorage(key, value, ttl = 3600000) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl, // Set the expiry time (TTL in ms)
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export function removeItemFromStorage(key) {
  localStorage.removeItem(key);
}

export function formatCurrency(number, currency, symbol = false) {
  return new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: currency || 'SGD',
    currencyDisplay: symbol ? 'symbol' : 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
}

export function validateInternationalPhoneNumber(phoneNumber = '') {
  const numberWithPlus = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
  const phone = parsePhoneNumberFromString(numberWithPlus);
  // Check if the phone number is valid
  if (phone && phone.isValid()) {
    return { isValid: true, formattedNumber: phone.formatInternational() };
  } else {
    return { isValid: false, formattedNumber: null };
  }
}