import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_EP_ORDER = 'CREATE_EP_ORDER';
export const DELETE_EP_ORDER = 'DELETE_EP_ORDER';
export const EP_ORDER_FAILURE = 'EP_ORDER_FAILURE';
export const GET_EP_ORDERS = 'GET_EP_ORDERS';
export const GET_EP_ORDER_BY_ID = 'GET_EP_ORDER_BY_ID';
export const SET_EP_LOADING = 'SET_EP_LOADING';
export const SET_EP_PAYMENT = 'SET_EP_PAYMENT';
export const SET_STRIPE_KEY = 'SET_STRIPE_KEY';
export const UPDATE_EP_ORDER = 'UPDATE_EP_ORDER';

export function getEpOrders() {
  const request = axios.get(`${API_URL}/api/eporders`);
  return (dispatch) => {
    dispatch({
      type: SET_EP_LOADING,
      payload: true,
    });
    request.then((response) => dispatch({
      type: GET_EP_ORDERS,
      payload: response.data
    })).catch((err) => {
      console.log(err);
      dispatch({
        type: EP_ORDER_FAILURE,
      });
    });
  };
}

export function getEpOrderById(epOrderId) {
  const request = axios.get(`${API_URL}/api/eporders/${epOrderId}`);
  return (dispatch) => {
    request.then((response) => {
      let epOrder = null;
      if (response.data && response.data?.epOrder?.length > 0) {
        epOrder = response.data.epOrder[0];
      }
      return dispatch({
        type: GET_EP_ORDER_BY_ID,
        payload: { epOrder }
      })
    });
  };
}

export function createEpOrder(epOrder) {
  return async(dispatch) => {
    try {
      dispatch({ type: SET_EP_LOADING, payload: true });
      const res = await axios.post(`${API_URL}/api/eporders/create`, epOrder);
      if (res.data) {
        dispatch({
          type: CREATE_EP_ORDER,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EP_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: EP_ORDER_FAILURE });
      throw error;
    }
  };
}

export function updateEpOrder(update) {
  const request = axios.put(`${API_URL}/api/eporders/`, update);
  return (dispatch) => {
    dispatch({
      type: SET_EP_LOADING,
      payload: true,
    });
    request.then((response) => {
      const updatedEpOrder = response.data?.updatedEpOrder || null;
      if (updatedEpOrder) {
        dispatch(getEpOrderById(updatedEpOrder?._id));
      }
      dispatch({
        type: UPDATE_EP_ORDER,
        payload: response.data,
      });
    }).catch((err) => {
      console.log(err);
      dispatch({
        type: EP_ORDER_FAILURE,
      });
    });;
  };
}

export function deleteEpOrder(id, deleteType) {
  const request = axios.delete(`${API_URL}/api/eporders/${id}`, {
    data: { deleteType }
  });
  return (dispatch) => {
    dispatch({
      type: SET_EP_LOADING,
      payload: true,
    });
    request.then((response) => dispatch({
      type: DELETE_EP_ORDER,
      payload: response.data
    })).catch((err) => {
      console.log(err);
      dispatch({
        type: EP_ORDER_FAILURE,
      });
    });
  };
}

export function setEpPayment(payInfo) {
  return async(dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/api/eporders/pay`, payInfo);
      dispatch({
        type: SET_EP_PAYMENT,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: EP_ORDER_FAILURE });
      throw error;
    }
  };
};

export function sendInvoice(epOrderId, price) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_EP_LOADING, payload: true });
      const res = await axios.post(`${API_URL}/api/eporders/sendinvoice`, { epOrderId, price });
      if (res.data) {
        dispatch({ type: SET_EP_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: EP_ORDER_FAILURE });
      throw error;
    }
  }
}
