import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Auth0Provider } from './authServices/auth0/auth0Service';
import config from './authServices/auth0/auth0Config.json';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { Layout } from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeConfig/store';
import { SettingsProvider } from 'src/context/SettingsContext';
import { restoreSettings } from 'src/utils/settings';
// import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import SplashScreen from './components/custom/SplashScreen';
import './index.scss';
import './@fake-db';
import { ScrollProvider } from './context/ScrollContext';

const LazyApp = lazy(() => import('./App'));

// configureDatabase()

const settings = restoreSettings();

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin + process.env.REACT_APP_PUBLIC_PATH}>
    <Provider store={store}>
      <ScrollProvider>
        <SettingsProvider settings={settings}>
          <SnackbarProvider
            maxSnack={10}
            autoHideDuration={5000}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            classes=""
          >
            <Suspense fallback={<SplashScreen />}>
              <Layout>
                <IntlProviderWrapper>
                  <LazyApp />
                </IntlProviderWrapper>
              </Layout>
            </Suspense>
          </SnackbarProvider>
        </SettingsProvider>
      </ScrollProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
