import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_CURRENCY = '@currency/create-currency';
export const UPDATE_CURRENCY = '@currency/update-currency';
export const GET_CURRENCIES = '@currency/list-currency';
export const GET_CURRENCYLABELS = '@currency/list-currency-labels';

export function getCurrencies() {

    const request = axios.get(API_URL + '/api/currencies')

    return (dispatch) => {
        request.then((response) => dispatch({
            type: GET_CURRENCIES,
            payload: response.data
        }));
    };
}

export const getCurrencyLabels = (requestParams = null) => async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/api/currencies`);
      if (response.status === 200) {
        const list = [{ label: 'SGD', value: 'SGD' }];
        response.data.forEach(item => {
            list.push({
                label: item.name,
                value: item.name,
                id: item._id,
                rate: item.rate,
            });
        });
        dispatch({
          type: GET_CURRENCYLABELS,
          payload: list,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export function createCurrency(currency) {

    const request = axios.post(API_URL + `/api/currencies`, currency);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: CREATE_CURRENCY,
            payload: response.data
        }));
    };
}

export function updateCurrency(update) {
    const request = axios.put(API_URL + `/api/currencies/${update._id}`, update);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_CURRENCY,
            payload: response.data
        }));
    };
}

export function deleteCurrency(id) {
    axios.delete(API_URL + `/api/currencies/${id}`);
}