import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_FEE = '@fee/create-fee';
export const UPDATE_FEE = '@fee/update-fee';
export const GET_FEES = '@fee/list-fee';
export const CREATE_GROUP = '@fee/create-group';
export const UPDATE_GROUP = '@fee/update-group';
export const DELETE_GROUP = '@fee/delete-group';
export const GET_GROUPS = '@fee/list-groups';
export const UPDATE_COUNTRY = '@fee/update-country';
export const GET_JURISDICTION = '@fee/get-jurisdiction';
export const UPDATE_JURISDICTION = '@fee/update-jurisdiction';

export function getFees() {
  const request = axios.get(`${API_URL}/api/fees`)
  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_FEES,
      payload: response.data
    }));
  };
}

export function createFee(fee) {
  const request = axios.post(`${API_URL}/api/fees`, fee);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: CREATE_FEE,
      payload: response.data
    }));
  };
}

export function updateFee(update) {
  const request = axios.put(`${API_URL}/api/fees/`, update);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_FEE,
      payload: response.data
    }));
  };
}

export function deleteFee(id) {
  axios.delete(`${API_URL}/api/fees/${id}`);
}

export function getGroups() {
  const request = axios.get(`${API_URL}/api/groups`)
  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_GROUPS,
      payload: response.data
    }));
  };
}

export function createGroup(group) {
  const request = axios.post(`${API_URL}/api/groups`, group);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: CREATE_GROUP,
      payload: response.data
    }));
  };
}

export function updateGroup(update) {
  const request = axios.put(`${API_URL}/api/groups/${update._id}`, update);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_GROUP,
      payload: response.data
    }));
  };
}

export function deleteGroup(group) {
  const request = axios.delete(`${API_URL}/api/groups/${group._id}`);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: DELETE_GROUP,
      payload: group,
    }));
  };
}

export const getJurisdiction = () => async (dispatch) => {
  const defaultJurisdiction = {
    one: 100,
    two: 100,
    three: 100,
    four: 100,
    five: 100,
  };
  try {
    const response = await axios.get(`${API_URL}/api/fees/getJurisdiction`);
    if (response.status === 200) {
      dispatch({
        type: GET_JURISDICTION,
        payload: response.data.length > 0 ? response.data[0] : defaultJurisdiction,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export function updateJurisdiction(jurisdiction) {
  const request = axios.post(`${API_URL}/api/fees/jurisdictions`, jurisdiction);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_JURISDICTION,
      payload: response.data
    }));
  };
}

export function updateCountries(countryList) {
  const request = axios.put(`${API_URL}/api/groups/countries`, countryList);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_COUNTRY,
      payload: response.data
    }));
  };
};