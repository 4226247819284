/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_ORDERS,
  GET_ORDER_HISTORY,
  CREATE_ORDER,
  UPDATE_ORDER,
  SET_PAYMENT,
  RESET_ORDERS,
  DELETE_ORDERS,
  SET_ORDER_LOADING,
  DELETE_ORDER
} from 'src/redux/actions/orderActions';

const initialState = {
  orders: null,
  historyList: null,
  order: null,
  paid: 'false',
  isOrderLoading: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_ORDER_LOADING: {
      const loading = action.payload
      return produce(state, (draft) => {
        draft.isOrderLoading = loading
      })
    }

    case GET_ORDERS: {
      const orders = action.payload
      return produce(state, (draft) => {
        draft.orders = orders
        draft.order = null
        draft.isOrderLoading = false
      })
    }

    case GET_ORDER_HISTORY: {
      const orders = action.payload;
      return produce(state, (draft) => {
        draft.historyList = orders
      })
    }

    case RESET_ORDERS: {
      const orders = action.payload
      return produce(state, (draft) => {
        draft.orders = orders
      })
    }

    case CREATE_ORDER: {
      const order = action.payload;

      return produce(state, (draft) => {
        draft.order = order;
        draft.orders = null;
      });
    }

    case UPDATE_ORDER: {
      const orders = action.payload;
      return produce(state, (draft) => {
        draft.orders = orders;
        draft.isOrderLoading = false;
      });
    }

    case DELETE_ORDER: {
      const orders = action.payload;
      return produce(state, (draft) => {
        draft.orders = orders;
        draft.isOrderLoading = false;
      });
    }

    case DELETE_ORDERS: {
      const orders = action.payload;
      return produce(state, (draft) => {
        draft.orders = orders;
        draft.isOrderLoading = false;
      });
    }

    case SET_PAYMENT: {
      const paid = action.payload;
      return produce(state, (draft) => {
        draft.paid = paid;
      });
    }

    default: {
      return state;
    }
  }
};

export default orderReducer;
