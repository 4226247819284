import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_PRODUCT = '@product/create-product';
export const UPDATE_PRODUCT = '@product/update-product';
export const GET_PRODUCT = '@product/get-product';
export const GET_PRODUCTS = '@product/list-product';
export const GET_CATEGORY_PRODUCTS = '@product/list-category-product';
export const PRODUCT_FAILURE = '@account/product-failure';
export const SET_ALLOWED_PRODUCTS = '@product/set-allowed-products';
export const SHOW_PRODUCT_TO_FRONT = '@product/show-product-to-front';
export const TOGGLE_CUSTOM_MODAL = 'TOGGLE_CUSTOM_MODAL';

export function getProducts() {

    const request = axios.get(`${API_URL}/api/products`)

    return (dispatch) => {
        request.then((response) => {
            const allowedProductList = response.data.filter((item) => item.showToFront === true);
            localStorage.setItem('allowedProducts', JSON.stringify(allowedProductList));
            dispatch({
                type: GET_PRODUCTS,
                payload: response.data
            })
            dispatch({
                type: SET_ALLOWED_PRODUCTS,
                payload: allowedProductList
            })
        });
    };
}

export function getProductsByCategoryId(categoryId) {
    const url = categoryId.toString() === 'all' ? `${API_URL}/api/products` : `${API_URL}/api/products/category/${categoryId}`;
    const request = axios.get(url);
    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_CATEGORY_PRODUCTS,
                payload: response.data
            })
        });
    };
}

export function getProductById(productId) {
    const request = axios.get(`${API_URL}/api/products/${productId}`);
    return (dispatch) => {
        request.then((response) => {
            dispatch({
                type: GET_PRODUCT,
                payload: response.data
            })
        });
    };
}

export function createProduct(product) {
    return async (dispatch) => {

        let formData = new FormData();

        let bulkFileNameList = [];
        for(let i = 0; i < product.bulkTemplateFileList.length; i ++) {
            formData.append('bulkTemplateFileDataList', product.bulkTemplateFileList[i].bulkTempleteFileData);
            bulkFileNameList.push({bulkTempleteFile: product.bulkTemplateFileList[i].bulkTempleteFile});
        }
        formData.append('bulkTemplateFileNameList', JSON.stringify(bulkFileNameList));

        let formsFileNameList = [];
        for(let i = 0; i < product.formsFileList.length; i ++) {
            formData.append('formsFileDataList', product.formsFileList[i].formsFileData);
            formsFileNameList.push({formsFile: product.formsFileList[i].formsFile});
        }
        formData.append('formsFileNameList', JSON.stringify(formsFileNameList));
        formData.append('name', product.name);
        formData.append('price', product.price);
        formData.append('optionFee1', JSON.stringify(product.optionFee1));
        formData.append('optionFee2', JSON.stringify(product.optionFee2));
        formData.append('isFeeOption', product.isFeeOption);
        formData.append('iMapFee', JSON.stringify(product.iMapFee));
        formData.append('isIMapOption', product.isIMapOption);
        formData.append('description', product.description);
        formData.append('country', product.country);
        formData.append('consent', product.consent);
        
        formData.append('categoryId', product.categoryId);
        formData.append('categoryName', product.categoryName);

        formData.append('company_names', product.company_names);
        formData.append('company_ids', product.company_ids);
        formData.append('serviceLevel', product.serviceLevel);
        formData.append('serviceDescription', product.serviceDescription);
        formData.append('mandDescription', product.mandDescription);
        formData.append('isMand', product.isMand);
        try {
            const res = await axios.post(`${API_URL}/api/products`, formData);

            dispatch({
                type: CREATE_PRODUCT,
                payload: res
            });
        } catch (error) {
            dispatch({ type: PRODUCT_FAILURE });
            throw error;
        }
    };
}

export function updateProduct(product) {
    return async (dispatch) => {

        let formData = new FormData();

        let bulkFileNameList = [];
        for(let i = 0; i < product.bulkNewFileList.length; i ++) {
            formData.append('bulkTemplateFileDataList', product.bulkNewFileList[i].bulkTempleteFileData);
            bulkFileNameList.push({bulkTempleteFile: product.bulkNewFileList[i].bulkTempleteFile});
        }
        formData.append('bulkTemplateFileNameList', JSON.stringify(bulkFileNameList));

        let formsFileNameList = [];
        for(let i = 0; i < product.formsNewFileList.length; i ++) {
            formData.append('formsFileDataList', product.formsNewFileList[i].formsFileData);
            formsFileNameList.push({formsFile: product.formsNewFileList[i].formsFile});
        }
        formData.append('formsFileNameList', JSON.stringify(formsFileNameList));

        formData.append('name', product.name);
        formData.append('price', product.price);
        formData.append('optionFee1', JSON.stringify(product.optionFee1));
        formData.append('optionFee2', JSON.stringify(product.optionFee2));
        formData.append('isFeeOption', product.isFeeOption);
        formData.append('iMapFee', JSON.stringify(product.iMapFee));
        formData.append('isIMapOption', product.isIMapOption);
        formData.append('description', product.description);
        formData.append('country', product.country);
        formData.append('consent', product.consent);

        formData.append('categoryId', product.categoryId);
        formData.append('categoryName', product.categoryName);

        formData.append('company_names', product.company_names);
        formData.append('company_ids', product.company_ids);
        formData.append('serviceLevel', product.serviceLevel);
        formData.append('serviceDescription', product.serviceDescription);
        formData.append('mandDescription', product.mandDescription);
        formData.append('isMand', product.isMand);
        
        formData.append('bulkOriginFileList', JSON.stringify(product.bulkOriginFileList));
        formData.append('formsOriginFileList', JSON.stringify(product.formsOriginFileList));
        formData.append('showToFront', product.showToFront);
        formData.append('frontDisplayPlace', product.frontDisplayPlace);

        try {
            const res = await axios.put(`${API_URL}/api/products/${product._id}`, formData);

            dispatch({
                type: CREATE_PRODUCT,
                payload: res
            });
        } catch (error) {
            dispatch({ type: PRODUCT_FAILURE });
            throw error;
        }
    };
}

export async function deleteProduct(id) {
    await axios.delete(`${API_URL}/api/products/${id}`);
} 

export const updateAllowedProducts = (products) => (dispatch) => {
    dispatch({
        type: SET_ALLOWED_PRODUCTS,
        payload: products,
    });
};

export const showProductToFront = ({ product, value }) => async (dispatch) => {
    try {
      const response = await axios.post(`${API_URL}/api/products/showtofrontend`,
        { id: product._id, value: value }
      );
      if (response.status === 200) {
        dispatch({
          type: SHOW_PRODUCT_TO_FRONT,
          payload: product,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const toggleCustomModal = (visibility, service = {price: 0, currency: 'SGD'}) => (dispatch) => {
    dispatch({
        type: TOGGLE_CUSTOM_MODAL,
        payload: { visibility, service },
    });
};