import produce from 'immer';
import {
  GET_FEES,
  CREATE_FEE,
  UPDATE_FEE,
  GET_GROUPS,
  UPDATE_GROUP,
  DELETE_GROUP,
  CREATE_GROUP,
  GET_JURISDICTION,
  UPDATE_JURISDICTION
} from 'src/redux/actions/feeActions';

const initialState = {
  fees: null,
  fee: null,
  groups: null,
  group: null,

  jurisdiction: null,
};

const feeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEES:
      {
        const fees = action.payload
        return produce(state, (draft) => {
          draft.fees = fees
          draft.fee = null
        })
      }

    case CREATE_FEE:
      {
        const fee = action.payload;
        return produce(state, (draft) => {
          draft.fee = fee;
        });
      }

    case UPDATE_FEE:
      {
        const fee = action.payload;
        return produce(state, (draft) => {
          draft.fee = fee;
        });
      }

    case GET_GROUPS:
      {
        const groups = action.payload
        return produce(state, (draft) => {
          draft.groups = groups
          draft.group = null
        })
      }

    case CREATE_GROUP:
      {
        const group = action.payload;
        return produce(state, (draft) => {
          draft.group = group;
        });
      }

    case UPDATE_GROUP:
      {
        const group = action.payload;
        return produce(state, (draft) => {
          draft.group = group;
        });
    }

    case DELETE_GROUP:
      {
        const group = action.payload;
        return produce(state, (draft) => {
          draft.group = group;
        });
      }

    case GET_JURISDICTION:
      {
        const jurisdiction = action.payload;
        return produce(state, (draft) => {
          draft.jurisdiction = jurisdiction;
        });
      }

    case UPDATE_JURISDICTION:
      {
        const jurisdiction = action.payload;
        return produce(state, (draft) => {
          draft.jurisdiction = jurisdiction;
        });
      }

    default:
      {
        return state;
      }
  }
};

export default feeReducer;