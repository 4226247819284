import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const GET_HANDSHAKE_BALANCE = '@handshake/get-balance';
export const GET_HANDSHAKE_DATA = '@handshake/get-custom-search';
export const HANDSHAKE_FAILURE = '@handshake/error';

export const getHandshakeBalance = () => async(dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/api/handshake/checkbalance`);
    if (response.status === 200) {
      dispatch({
        type: GET_HANDSHAKE_BALANCE,
        payload: response.data
      });
    }
  } catch (error) {
    dispatch({ type: HANDSHAKE_FAILURE });
    throw error;
  }
};

export const handshakeCustomSearch = (data) => async(dispatch) => {
  try {
    const response = await axios.post(`${API_URL}/api/handshake/customsearch`, data);
    if (response.status === 200) {
      dispatch({
        type: GET_HANDSHAKE_DATA,
        payload: response.data
      });
    }
  } catch (error) {
    dispatch({ type: HANDSHAKE_FAILURE });
    throw error;
  }
};
