/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_PREORDERS,
  GET_PREORDER,
  GET_PREORDER_HISTORY,
  CREATE_PREORDER,
  DELETE_PREORDER,
  RESET_PREORDERS,
  UPDATE_PREORDER,
  SET_PREORDER_PAYMENT,
  GET_PREORDER_FORMS,
  SET_PREORDER_LOADING,
  SET_PREORDER_TEMP_USER,
  SUBMIT_CANDIDATE,
  DELETE_CANDINFO,
  FAIL_CANDINFO,
  UPDATE_PREORDER_CANDIDATE,
  UPDATE_PREORDER_FORMS,
  ACCEPT_CANDIDATE,
  ACCEPT_CANDIDATE_REPORT,
} from 'src/redux/actions/preOrderActions';

const initialState = {
  preOrders: null,
  historyList: null,
  preOrder: null,
  paid: 'false',
  preOrderForms: null,
  isLoadingPreOrders: false,
  tempUser: null,
};

const preOrderReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PREORDER_LOADING: {
      const isLoading = action.payload
      return produce(state, (draft) => {
        draft.isLoadingPreOrders = isLoading
      })
    }

    case GET_PREORDERS: {
      const preOrders = action.payload
      return produce(state, (draft) => {
        draft.preOrders = preOrders
        draft.preOrder = null
        draft.isLoadingPreOrders = false
      })
    }

    case GET_PREORDER: {
      const preOrder = action.payload
      return produce(state, (draft) => {
        draft.preOrder = preOrder
        draft.isLoadingPreOrders = false;
      })
    }

    case GET_PREORDER_HISTORY: {
      const preOrders = action.payload;
      return produce(state, (draft) => {
        draft.historyList = preOrders
      })
    }

    case RESET_PREORDERS: {
      const preOrders = action.payload
      return produce(state, (draft) => {
        draft.preOrders = preOrders
      })
    }

    case CREATE_PREORDER: {
      const preOrder = action.payload;

      return produce(state, (draft) => {
        draft.preOrder = preOrder;
        draft.isLoadingPreOrders = false
      });
    }

    case UPDATE_PREORDER: {
      const preOrder = action.payload;
      return produce(state, (draft) => {
        draft.preOrder = preOrder;
        draft.isLoadingPreOrders = false;
      });
    }
    
    case UPDATE_PREORDER_CANDIDATE: {
      return produce(state, (draft) => {
        draft.preOrder = null;
        draft.isLoadingPreOrders = false;
      });
    }

    case ACCEPT_CANDIDATE: {
      const preOrder = action.payload;
      return produce(state, (draft) => {
        draft.preOrder = preOrder;
        draft.preOrders = null;
        draft.isLoadingPreOrders = false;
      });
    }

    case ACCEPT_CANDIDATE_REPORT: {
      const preOrder = action.payload;
      return produce(state, (draft) => {
        draft.preOrder = preOrder;
        draft.preOrders = null;
        draft.isLoadingPreOrders = false;
      });
    }

    case DELETE_PREORDER: {
      const preOrder = action.payload;
      return produce(state, (draft) => {
        draft.preOrder = preOrder;
      });
    }

    case SET_PREORDER_PAYMENT: {
      const paid = action.payload;
      return produce(state, (draft) => {
        draft.paid = paid;
      });
    }

    case GET_PREORDER_FORMS: {
      const forms = action.payload;
      return produce(state, (draft) => {
        draft.preOrderForms = forms;
      });
    }
    case UPDATE_PREORDER_FORMS: {
      const forms = action.payload;
      return produce(state, (draft) => {
        draft.preOrderForms = forms;
        draft.isLoadingPreOrders = false;
      });
    }
    case SET_PREORDER_TEMP_USER: {
      const tempUser = action.payload;
      return produce(state, (draft) => {
        draft.tempUser = tempUser;
      });
    }
    case SUBMIT_CANDIDATE: {
      const preOrder = action.payload;
      return produce(state, (draft) => {
        draft.preOrder = preOrder;
        draft.isLoadingPreOrders = false;
      });
    }
    case DELETE_CANDINFO: {
      return produce(state, (draft) => {
        draft.preOrder = null;
      });
    }
    case FAIL_CANDINFO: {
      return produce(state, (draft) => {
        draft.preOrder = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default preOrderReducer;
