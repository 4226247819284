import axios from 'axios';
import { getItemFromStorage } from 'src/utils/utils';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_PREORDER = '@preorder/create-preorder';
export const UPDATE_PREORDER = '@preorder/update-preorder';
export const UPDATE_PREORDER_CANDIDATE = '@preorder/update-candidate';
export const GET_PREORDERS = '@preorder/list-preorder';
export const GET_PREORDER = '@preorder/get-preorder';
export const GET_PREORDER_HISTORY = '@preorder/list-preorder-history';
export const RESET_PREORDERS = '@preorder/reset-preorder';
export const DELETE_PREORDER = '@preorder/delete-preorder';
export const DELETE_PREORDERS = '@preorder/delete-preorders';
export const PREORDER_FAILURE = '@preorder/preorder-failure';
export const SET_PREORDER_PAYMENT = '@preorder/set-payment';
export const GET_PREORDER_FORMS = '@preorder/get-forms';
export const UPDATE_PREORDER_FORMS = '@preorder/update-forms';
export const SET_PREORDER_LOADING = '@preorder/set-loading';
// customer's temp login for candidates
export const SET_PREORDER_TEMP_USER = '@preorder/set-temp-user';
export const SUBMIT_CANDIDATE = '@preorder/submit-candidate';
export const DELETE_CANDINFO = '@preorder/delete-candidate-info';
export const FAIL_CANDINFO = '@preorder/fail-candidate-info';
export const ACCEPT_CANDIDATE = '@preorder/accept-candidate';
export const ACCEPT_CANDIDATE_REPORT = '@preorder/accept-candidate-report';

export const getPreOrders = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PREORDER_LOADING,
      payload: true,
    });
    const response = await axios.post(`${API_URL}/api/preorders/getbyreviewerid`, params);
    if (response.status === 200) {
      dispatch({
        type: GET_PREORDERS,
        payload: response.data
      });
    }
  } catch (error) {
    dispatch({ type: PREORDER_FAILURE });
    throw error;
  }
};

export const getPreOrderById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/api/preorders/${id}`);
    if (response.status === 200) {
      dispatch({
        type: GET_PREORDER,
        payload: response.data?.length > 0 ? response.data[0] : null
      });
    }
  } catch (error) {
    dispatch({ type: PREORDER_FAILURE });
    throw error;
  }
};

export function createPreOrder(order) {
  return async(dispatch) => {
    try {
      const formData = new FormData();

      formData.append('preOrderNo', order.preOrderNo);
      formData.append('createUser', order.createUser);
      formData.append('clientName', order.clientName);
      formData.append('customerName', order.customerName);
      formData.append('projectName', order.projectName);
      formData.append('reviewer1', order.reviewer1);
      formData.append('reviewer2', order.reviewer2);
      formData.append('password', order.password);
      formData.append('accessStatus', order.accessStatus);
      formData.append('fee', order.fee);
      formData.append('currency', order.currency);
      formData.append('customer', order.customer);
      formData.append('snRef', order.snRef);
      formData.append('snStart', order.snStart);

      const res = await axios.post(`${API_URL}/api/preorders`, formData);

      dispatch({
        type: CREATE_PREORDER,
        payload: res
      });
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function createCandidate(candidate) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });

      let formData = new FormData();

      if (candidate?.candidateCreationType === 'form') {
        formData.append('candidateEmail', candidate.candidateEmail);
        formData.append('accessKey', candidate.accessKey);
      } else {
        let appFormFiles = [];
        if(candidate.appFormFiles !== null) {
          candidate.appFormFiles.forEach(item => {
            formData.append('appFormFile', item);
            appFormFiles.push(item.name);
          })
        }
        formData.append('appFormFileNameList', JSON.stringify(appFormFiles));
        
        let consentFormFiles = [];
        if(candidate.consentFormFiles !== null) {
          candidate.consentFormFiles.forEach(item => {
            formData.append('consentFormFile', item);
            consentFormFiles.push(item.name);
          })
        }
        formData.append('consentFormFileNameList', JSON.stringify(consentFormFiles));

        let idDlFiles = [];
        if(candidate.idDlFiles !== null) {
          candidate.idDlFiles.forEach(item => {
            formData.append('idDlFile', item);
            idDlFiles.push(item.name);
          })
        }
        formData.append('idDlFileNameList', JSON.stringify(idDlFiles));

        let eduCertFiles = [];
        if(candidate.eduCertFiles !== null) {
          candidate.eduCertFiles.forEach(item => {
            formData.append('eduCertFile', item);
            eduCertFiles.push(item.name);
          })
        }
        formData.append('eduCertFileNameList', JSON.stringify(eduCertFiles));

        let qualifFiles = [];
        if(candidate.qualifFiles !== null) {
          candidate.qualifFiles.forEach(item => {
            formData.append('qualifFile', item);
            qualifFiles.push(item.name);
          })
        }
        formData.append('qualifFileNameList', JSON.stringify(qualifFiles));

        let additionalDocFiles = [];
        if(candidate.additionalDocFiles !== null) {
          candidate.additionalDocFiles.forEach(item => {
            formData.append('additionalDocFile', item);
            additionalDocFiles.push(item.name);
          })
        }
        formData.append('additionalDocFileNameList', JSON.stringify(additionalDocFiles));
      }
      
      formData.append('candidateCreationType', candidate.candidateCreationType);
      formData.append('candidateName', candidate.candidateName);
      formData.append('checkLevel', candidate.checkLevel);
      formData.append('currentStatus', candidate.currentStatus);
      formData.append('remark1', candidate.remark1);
      formData.append('userid', candidate.userid);
      formData.append('username', candidate.username);
      formData.append('preOrderId', candidate.preOrderId);

      const res = await axios.post(`${API_URL}/api/preorders/createcandidate`, formData);

      dispatch({
        type: UPDATE_PREORDER,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function acceptCandidate(formData) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });

      const response = await axios.post(`${API_URL}/api/preorders/acceptcandidate`, formData);

      if (response.status === 200) {
        dispatch({
          type: ACCEPT_CANDIDATE,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function acceptCandidateReport(type, formData) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });

      const response = await axios.post(`${API_URL}/api/preorders/${type}`, formData);

      if (response.status === 200) {
        dispatch({
          type: ACCEPT_CANDIDATE_REPORT,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function uploadCandidateReport(formData) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });

      const response = await axios.post(`${API_URL}/api/preorders/uploadcandreport`, formData);

      if (response.status === 200) {
        dispatch({
          type: UPDATE_PREORDER,
          payload: response.data
        });
      }

      dispatch({
        type: UPDATE_PREORDER,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function deleteCandidate(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });

      const response = await axios.post(`${API_URL}/api/preorders/delcand`, data);

      if (response.status === 200) {
        dispatch({
          type: UPDATE_PREORDER,
          payload: response.data
        });
      }

      dispatch({
        type: UPDATE_PREORDER,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function UpdateCandidate(candidate) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });
      let formData = new FormData();

      let appFormFiles = [];
      if(candidate.appFormFileList !== null) {
        candidate.appFormFileList.forEach(item => {
          formData.append('appFormFile', item.fileData);
          appFormFiles.push(item.name);
        })
      }
      formData.append('appFormFileNameList', JSON.stringify(appFormFiles));
      formData.append('appFormFileListOld', JSON.stringify(candidate.appFormFileListOld));
      
      let consentFormFiles = [];
      if(candidate.consentFormFileList !== null) {
        candidate.consentFormFileList.forEach(item => {
          formData.append('consentFormFile', item.fileData);
          consentFormFiles.push(item.name);
        })
      }
      formData.append('consentFormFileNameList', JSON.stringify(consentFormFiles));
      formData.append('consentFormFileListOld', JSON.stringify(candidate.consentFormFileListOld));

      let idDlFiles = [];
      if(candidate.idDlFileList !== null) {
        candidate.idDlFileList.forEach(item => {
          formData.append('idDlFile', item.fileData);
          idDlFiles.push(item.name);
        })
      }
      formData.append('idDlFileNameList', JSON.stringify(idDlFiles));
      formData.append('idDlFileListOld', JSON.stringify(candidate.idDlFileListOld));

      let eduCertFiles = [];
      if(candidate.eduCertList !== null) {
        candidate.eduCertList.forEach(item => {
          formData.append('eduCertFile', item.fileData);
          eduCertFiles.push(item.name);
        })
      }
      formData.append('eduCertFileNameList', JSON.stringify(eduCertFiles));
      formData.append('eduCertListOld', JSON.stringify(candidate.eduCertListOld));

      let qualifFiles = [];
      if(candidate.qualifFileList !== null) {
        candidate.qualifFileList.forEach(item => {
          formData.append('qualifFile', item.fileData);
          qualifFiles.push(item.name);
        })
      }
      formData.append('qualifFileNameList', JSON.stringify(qualifFiles));
      formData.append('qualifFileListOld', JSON.stringify(candidate.qualifFileListOld));

      let additionalDocFiles = [];
      if(candidate.additionalDocFileList !== null) {
        candidate.additionalDocFileList.forEach(item => {
          formData.append('additionalDocFile', item.fileData);
          additionalDocFiles.push(item.name);
        })
      }
      formData.append('additionalDocFileNameList', JSON.stringify(additionalDocFiles));
      formData.append('additionalDocFileListOld', JSON.stringify(candidate.additionalDocFileListOld));

      formData.append('candidateName', candidate.candidateName);
      // formData.append('remark1', candidate.remark1);
      formData.append('checkLevel', candidate.checkLevel);
      formData.append('preOrderId', candidate.preOrderId);
      formData.append('candId', candidate.candId);
      // formData.append('userid', candidate.userid);
      // formData.append('username', candidate.username);

      const res = await axios.post(`${API_URL}/api/preorders/updatecandidate`, formData);

      dispatch({
        type: UPDATE_PREORDER_CANDIDATE,
        payload: res
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export function UpdateCandidateInfo(candidate, accessToken) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });
      const formData = new FormData();
      let consentFormFiles = [];
      if(candidate.consentFormFileList !== null) {
          candidate.consentFormFileList.forEach(item => {
              formData.append('consentFormFile', item.fileData);
              consentFormFiles.push(item.name);
          })
      }
      formData.append('consentFormFileNameList', JSON.stringify(consentFormFiles));
      formData.append('consentFormFileListOld', JSON.stringify(candidate.consentFormFileListOld));

      let idDlFiles = [];
      if(candidate.idDlFileList !== null) {
          candidate.idDlFileList.forEach(item => {
              formData.append('idDlFile', item.fileData);
              idDlFiles.push(item.name);
          })
      }
      formData.append('idDlFileNameList', JSON.stringify(idDlFiles));
      formData.append('idDlFileListOld', JSON.stringify(candidate.idDlFileListOld));

      let eduCertFiles = [];
      if(candidate.eduCertList !== null) {
          candidate.eduCertList.forEach(item => {
              formData.append('eduCertFile', item.fileData);
              eduCertFiles.push(item.name);
          })
      }
      formData.append('eduCertFileNameList', JSON.stringify(eduCertFiles));
      formData.append('eduCertListOld', JSON.stringify(candidate.eduCertListOld));

      let qualifFiles = [];
      if(candidate.qualifFileList !== null) {
          candidate.qualifFileList.forEach(item => {
              formData.append('qualifFile', item.fileData);
              qualifFiles.push(item.name);
          })
      }
      formData.append('qualifFileNameList', JSON.stringify(qualifFiles));
      formData.append('qualifFileListOld', JSON.stringify(candidate.qualifFileListOld));

      let additionalDocFiles = [];
      if(candidate.additionalDocFileList !== null) {
          candidate.additionalDocFileList.forEach(item => {
              formData.append('additionalDocFile', item.fileData);
              additionalDocFiles.push(item.name);
          })
      }
      formData.append('additionalDocFileNameList', JSON.stringify(additionalDocFiles));
      formData.append('additionalDocFileListOld', JSON.stringify(candidate.additionalDocFileListOld));
      
      if (candidate?.editBy === 'customer') {
          formData.append('candidateEmail', candidate.candidateEmail);
          formData.append('checkLevel', candidate.checkLevel);
      }
      formData.append('isPassportNull', candidate.isPassportNull);
      formData.append('candidateInfoId', candidate.candidateInfoId);
      formData.append('fullName', candidate.fullName);
      formData.append('idNo', candidate.idNo);
      formData.append('idIssuingCountry', candidate.idIssuingCountry);
      formData.append('pptNo', candidate.pptNo);
      formData.append('pptIssuingCountry', candidate.pptIssuingCountry);
      formData.append('birthday', candidate.birthday);
      formData.append('countryOfBirth', candidate.countryOfBirth);
      formData.append('nationality', candidate.nationality);
      formData.append('address', candidate.address);
      
      formData.append('isDlNull', candidate.isDlNull);
      formData.append('motorVehicleNo', candidate.motorVehicleNo);
      formData.append('dlNo', candidate.dlNo);
      formData.append('dlIssuingCountry', candidate.dlIssuingCountry);
      formData.append('dlIssueDate', candidate.dlIssueDate);
      formData.append('dlExpireDate', candidate.dlExpireDate);

      formData.append('isEducationNull', candidate.isEducationNull);
      formData.append('institutionName', candidate.institutionName);
      formData.append('institutionCountry', candidate.institutionCountry);
      formData.append('courseName', candidate.courseName);
      formData.append('courseType', candidate.courseType);
      formData.append('startDate', candidate.startDate);
      formData.append('graduationDate', candidate.graduationDate);
      formData.append('cgpa', candidate.cgpa);
      formData.append('studentIdNo', candidate.studentIdNo);

      // formData.append('isEmploymentNull1', candidate.isEmploymentNull1);
      formData.append('employerName1', candidate.employerName1);
      formData.append('employCountry1', candidate.employCountry1);
      formData.append('startDate1', candidate.startDate1);
      formData.append('endDate1', candidate.endDate1);
      formData.append('jobTitle1', candidate.jobTitle1);
      formData.append('reasonForLeaving1', candidate.reasonForLeaving1);
      formData.append('lastSalary1', candidate.lastSalary1);
      formData.append('supervisorName1', candidate.supervisorName1);
      formData.append('supervisorTitle1', candidate.supervisorTitle1);
      formData.append('supervisorEmail1', candidate.supervisorEmail1);
      formData.append('supervisorPhone1', candidate.supervisorPhone1);

      formData.append('isEmploymentNull2', candidate.isEmploymentNull2);
      formData.append('employerName2', candidate.employerName2);
      formData.append('employCountry2', candidate.employCountry2);
      formData.append('startDate2', candidate.startDate2);
      formData.append('endDate2', candidate.endDate2);
      formData.append('jobTitle2', candidate.jobTitle2);
      formData.append('reasonForLeaving2', candidate.reasonForLeaving2);
      formData.append('lastSalary2', candidate.lastSalary2);
      formData.append('supervisorName2', candidate.supervisorName2);
      formData.append('supervisorTitle2', candidate.supervisorTitle2);
      formData.append('supervisorEmail2', candidate.supervisorEmail2);
      formData.append('supervisorPhone2', candidate.supervisorPhone2);

      formData.append('isQualificationNull', candidate.isQualificationNull);
      formData.append('membershipTitle', candidate.membershipTitle);
      formData.append('memebershipBody', candidate.memebershipBody);
      formData.append('membershipNo', candidate.membershipNo);
      formData.append('membershipIssuingCountry', candidate.membershipIssuingCountry);
      formData.append('membershipIssuingDate', candidate.membershipIssuingDate);
      formData.append('membershipExpirationDate', candidate.membershipExpirationDate);

      formData.append('readTerms', candidate.declarationReadTerms);
      formData.append('editBy', candidate.editBy);
      formData.append('newCandidateInfoStatus', candidate?.newCandidateInfoStatus);

      await axios.post(`${API_URL}/api/candidateinfo/updatecandidate`, formData, {
        headers: {
          'x-auth-token': accessToken,
        },
      });
      dispatch({
        type: UPDATE_PREORDER_CANDIDATE,
        payload: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: false,
      });
    }
  }
}

export function DeleteCandidateWithInfo(data) {
  return async(dispatch) => {
    try {
      const accessToken = getItemFromStorage('customerAccessToken');
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });
      const res = await axios.post(`${API_URL}/api/candidateinfo/deletecandidate`, data, {
        headers: {
          'x-auth-token': accessToken,
        },
      });
      if(res.data) {
        dispatch({
          type: DELETE_CANDINFO,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    } finally {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: false,
      });
    }
  };
}

export function FailCandidateWithInfo(candidate) {
  return async(dispatch) => {
    try {
      const accessToken = getItemFromStorage('customerAccessToken');
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: true,
      });
      const res = await axios.post(`${API_URL}/api/candidateinfo/fail`, candidate, {
        headers: {
          'x-auth-token': accessToken,
        },
      });
      if(res.data) {
        dispatch({
          type: FAIL_CANDINFO,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    } finally {
      dispatch({
        type: SET_PREORDER_LOADING,
        payload: false,
      });
    }
  };
}

export function UpdateCandForm(sampleform) {
  return async(dispatch) => {
    try {
      let formData = new FormData();

      let appFormFiles = [];
      if(sampleform.appFormFileList !== null) {
        sampleform.appFormFileList.forEach(item => {
          formData.append('appFormFile', item.fileData);
          appFormFiles.push(item.name);
        })
      }
      formData.append('appFormFileNameList', JSON.stringify(appFormFiles));
      formData.append('appFormFileListOld', JSON.stringify(sampleform.appFormFileListOld));
      
      let consentFormFiles = [];
      if(sampleform.consentFormFileList !== null) {
        sampleform.consentFormFileList.forEach(item => {
          formData.append('consentFormFile', item.fileData);
          consentFormFiles.push(item.name);
        })
      }
      formData.append('consentFormFileNameList', JSON.stringify(consentFormFiles));
      formData.append('consentFormFileListOld', JSON.stringify(sampleform.consentFormFileListOld));

      let idDlFiles = [];
      if(sampleform.idDlFileList !== null) {
        sampleform.idDlFileList.forEach(item => {
          formData.append('idDlFile', item.fileData);
          idDlFiles.push(item.name);
        })
      }
      formData.append('idDlFileNameList', JSON.stringify(idDlFiles));
      formData.append('idDlFileListOld', JSON.stringify(sampleform.idDlFileListOld));

      let eduCertFiles = [];
      if(sampleform.eduCertList !== null) {
        sampleform.eduCertList.forEach(item => {
          formData.append('eduCertFile', item.fileData);
          eduCertFiles.push(item.name);
        })
      }
      formData.append('eduCertFileNameList', JSON.stringify(eduCertFiles));
      formData.append('eduCertListOld', JSON.stringify(sampleform.eduCertListOld));

      let qualifFiles = [];
      if(sampleform.qualifFileList !== null) {
        sampleform.qualifFileList.forEach(item => {
          formData.append('qualifFile', item.fileData);
          qualifFiles.push(item.name);
        })
      }
      formData.append('qualifFileNameList', JSON.stringify(qualifFiles));
      formData.append('qualifFileListOld', JSON.stringify(sampleform.qualifFileListOld));

      let additionalDocFiles = [];
      if(sampleform.additionalDocFileList !== null) {
        sampleform.additionalDocFileList.forEach(item => {
          formData.append('additionalDocFile', item.fileData);
          additionalDocFiles.push(item.name);
        })
      }
      formData.append('additionalDocFileNameList', JSON.stringify(additionalDocFiles));
      formData.append('additionalDocFileListOld', JSON.stringify(sampleform.additionalDocFileListOld));

      formData.append('formid', sampleform.formId);

      const res = await axios.post(`${API_URL}/api/preorders/updatecandform`, formData);
      if (res.status === 200) {
        dispatch({
          type: UPDATE_PREORDER_FORMS,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
}

export const closePreOrder = (preOrder) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}/api/preorders/updatepreorderstatus`, {
      orderid: preOrder._id,
      currentstatus: preOrder.status,
    });
    if (response.status === 200) {
      dispatch({
        type: UPDATE_PREORDER,
        payload: preOrder
      });
    }
  } catch (error) {
    dispatch({ type: PREORDER_FAILURE });
    throw error;
  }
}

export function updatePreOrder(update) {
  const request = axios.put(`${API_URL}/api/preorders`, update);
  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PREORDER,
      payload: response.data
    }));
  };
}

export const deletePreOrder = (preOrder) => async (dispatch) => {
  try {
    const response = await axios.delete(`${API_URL}/api/preorders/${preOrder._id}`);
    if (response.status === 200) {
      dispatch({
        type: DELETE_PREORDER,
        payload: preOrder
      });
    }
  } catch (error) {
    dispatch({ type: PREORDER_FAILURE });
    throw error;
  }
};

export function setPreOrderPayment(payInfo) {
  return async(dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/api/preorders/pay`, payInfo);
      dispatch({
        type: SET_PREORDER_PAYMENT,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
};

export function uploadOrderFile(order, orderId) {
  return async(dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/api/preorders/upload/${orderId}`, order);
      dispatch({
          type: SET_PREORDER_PAYMENT,
          payload: res.data
      });
    } catch (error) {
      dispatch({ type: PREORDER_FAILURE });
      throw error;
    }
  };
};

export const getPreOrderForms = () => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}/api/preorders/forms`);
    if (response.status === 200) {
      dispatch({
        type: GET_PREORDER_FORMS,
        payload: response.data?.[0] || {}
      });
    }
  } catch (error) {
    dispatch({ type: PREORDER_FAILURE });
    throw error;
  }
};

export const submitCandidate = (data) => async (dispatch) => {
  try {
    const accessToken = getItemFromStorage('customerAccessToken');
    dispatch({
      type: SET_PREORDER_LOADING,
      payload: true,
    });
    const response = await axios.post(`${API_URL}/api/preorders/submitcandidate`, data, {
      headers: {
        'x-auth-token': accessToken,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: SUBMIT_CANDIDATE,
        payload: response.data
      });
    }
  } catch (error) {
    dispatch({ type: PREORDER_FAILURE });
    throw error;
  } finally {
    dispatch({
      type: SET_PREORDER_LOADING,
      payload: false,
    });
  }
};

export const setPrOrderLoading = (isLoading = true) => async (dispatch) => {
  dispatch({
    type: SET_PREORDER_LOADING,
    payload: isLoading,
  });
};