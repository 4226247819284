import React from 'react';
import * as Icon from 'react-feather';
import { UserRoles } from 'src/utils/constants';
const navigationConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.REVIEWER, UserRoles.CUSTOMER],
    navLink: '/app/dashboard',
  },
  {
    type: "groupHeader",
    groupTitle: "Management"
  },
  {
    id: "companies",
    title: "Companies",
    type: "item",
    icon: <Icon.Folder size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: '/app/companies',
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: '/app/users',
  },
  {
    id: "categories",
    title: "Product Grouping",
    type: "item",
    icon: <Icon.Layout size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: '/app/categories',
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Icon.Trello size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: '/app/products',
  },
  {
    id: "fees",
    title: "Fee",
    type: "collapse",
    icon: <Icon.DollarSign size={20} />,
    permissions: [UserRoles.ADMIN],
    children: [
      {
        id: "manageFee",
        title: "Manage Fee",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/fees/list"
      },
      {
        id: "listGroup",
        title: "List Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/fees/groups"
      },
      {
        id: "countryGroup",
        title: "Country Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/fees/country-groups"
      },
      {
        id: "jurisdictionDiscount",
        title: "Jurisdiction Discount",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/fees/jurisdiction-fees"
      },
    ]
  },
  {
    id: "forexSetup",
    title: "Forex Setup",
    type: "collapse",
    icon: <Icon.BarChart size={20} />,
    permissions: [UserRoles.ADMIN],
    children: [
      {
        id: "currencies",
        title: "Currency",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/forex/currencies"
      },
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "Applications"
  },
  {
    id: "orders",
    title: "Orders",
    type: "collapse",
    icon: <Icon.Clipboard size={20} />,
    permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.CUSTOMER],
    children: [
      {
        id: "orderCreate",
        title: "Create Order",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.CUSTOMER],
        navLink: "/app/orders/create"
      },
      {
        id: "orderList",
        title: "List Orders",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.CUSTOMER],
        navLink: "/app/orders/list"
      },
      {
        id: "orderHistory",
        title: "Order History",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.CUSTOMER],
        navLink: "/app/orders/history"
      },
      {
        id: "orderCustomSearch",
        title: "Custom Search",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.CUSTOMER],
        navLink: "/app/orders/custom-search"
      },
    ]
  },
  {
    id: "preorders",
    title: "EMEPMI Orders",
    type: "collapse",
    icon: <Icon.Codepen size={20} />,
    permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.REVIEWER],
    children: [
      {
        id: "preOrderList",
        title: "Order List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN, UserRoles.PARTNER, UserRoles.REVIEWER],
        navLink: "/app/preorders/list"
      },
      {
        id: "preOrderCandForms",
        title: "Forms",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/preorders/candforms"
      },
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "Reports"
  },
  {
    id: "actSumm",
    title: "Activity Summary",
    type: "collapse",
    icon: <Icon.Activity size={20} />,
    permissions: [UserRoles.ADMIN],
    children: [
      {
        id: "compSumm",
        title: "Company Summary",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/actsumm/company"
      },
      {
        id: "userSumm",
        title: "User Summary",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/actsumm/user"
      },
      {
        id: "apiSumm",
        title: "API Summary",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [UserRoles.ADMIN],
        navLink: "/app/actsumm/api"
      },
    ]
  },
  {
    id: "apiHistory",
    title: "API History",
    type: "item",
    icon: <Icon.Clock size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: "/app/apihistory",
  },
  {
    type: "groupHeader",
    groupTitle: "Settings"
  },
  {
    id: "userProfile",
    title: "My profile",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: [UserRoles.ADMIN, UserRoles.CUSTOMER, UserRoles.PARTNER, UserRoles.REVIEWER],
    navLink: "/app/profile",
  },
  {
    id: "accountSetting",
    title: "Account",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: "/app/account",
  },
  {
    id: "searchParameters",
    title: "Search Parameters",
    type: "item",
    icon: <Icon.Search size={20} />,
    permissions: [UserRoles.ADMIN],
    navLink: "/app/searchparam",
  },
];
export default navigationConfig
