/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    GET_COMPANIES,
    CREATE_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    GET_LINKED_USERS,
    RESET_LINKED_USERS
} from 'src/redux/actions/companyActions';

const initialState = {
    companies: null,
    company: null,
    linkedUsers: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) { 

    case GET_COMPANIES: {
      const companies = action.payload
      return produce(state, (draft) => {
        draft.companies = companies
        draft.company = null
      })
    }

    case CREATE_COMPANY: {
      const company = action.payload;

      return produce(state, (draft) => {
        draft.company = company;
      });
    }

    case UPDATE_COMPANY: {
      const company = action.payload;
      return produce(state, (draft) => {
        draft.company = company;
      });
    }

    case DELETE_COMPANY: {
      const company = action.payload;
      return produce(state, (draft) => {
        draft.company = company;
      });
    }

    case GET_LINKED_USERS: {
      const users = action.payload;
      return produce(state, (draft) => {
        draft.linkedUsers = users;
      });
    }

    case RESET_LINKED_USERS: {
      return produce(state, (draft) => {
        draft.linkedUsers = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default companyReducer;
