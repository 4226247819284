/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  UPDATE_ACCOUNT,
  COUNTRY,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  SELECT_CUSTOMER,
  UPDATE_COUNTRY,
  USERS,
  OKTA_LOGIN,
  ACCOUNT_LOADING,
  FORGOT_SUCCESS,
  FORGOT_FAIL
} from 'src/redux/actions/accountActions';

const initialState = {
  user: null,
  tempuser: null,
  users: [],
  customer: null,
  selectedCustomer: null,
  countries: null,
  error: null,
  isLoading: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_LOADING: {
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    }
    case FORGOT_SUCCESS: {
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    }
    case FORGOT_FAIL: {
      return produce(state, (draft) => {
        draft.isLoading = false;
      });
    }
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.tempuser = null;
      });
    }

    case LOGIN_SUCCESS: {
      const user = action.payload;
      return produce(state, (draft) => {
        draft.tempuser = user.user;
        draft.countries = user.countries;
        draft.error = null;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, (draft) => {
        // const error = action.payload;
        draft.error = 'error'
      });
    }

    case REGISTER_FAIL: {
      return produce(state, (draft) => {
        // Maybe store error
        draft.error = 'error'
      });
    }

    case REGISTER_SUCCESS: {
      // const { user } = action.payload;
      return produce(state, (draft) => {
        // draft.user = user;
      });
    }

    case COUNTRY: {
      const countries = action.payload;
      return produce(state, (draft) => {
        draft.countries = countries;
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.tempuser = null;
      });
    }

    case SILENT_LOGIN: {
      const user = action.payload;

      return produce(state, (draft) => {
        draft.user = user.user;
        draft.countries = user.countries;
      });
    }

    case OKTA_LOGIN: {
      return produce(state, (draft) => {
        draft.user = draft.tempuser
      });
    }

    case UPDATE_PROFILE: {

      const user = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_ACCOUNT: {
      const accountSettings = action.payload;

      return produce(state, (draft) => {
        draft.accountSettings = accountSettings;
      });
    }

    case CREATE_CUSTOMER: {
      const customer = action.payload;
      return produce(state, (draft) => {
        draft.customer = customer;
      });
    }

    case UPDATE_CUSTOMER: {
      const customer = action.payload;
      return produce(state, (draft) => {
        draft.customer = customer;
      });
    }

    case SELECT_CUSTOMER: {
      const customer = action.payload;
      return produce(state, (draft) => {
        draft.selectedCustomer = customer;
      });
    }

    case USERS: {
      const users = action.payload;
      return produce(state, (draft) => {
        draft.users = users;
      });
    }

    case UPDATE_COUNTRY: {
      const countries = action.payload;

      return produce(state, (draft) => {
        draft.countries = countries;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
