import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const GET_SEARCH_PARAMS = '@searchTerms/get-round-params';
export const ADD_SEARCH_ROUND = '@searchTerms/add-round';
export const UPDATE_SEARCH_ROUND = '@searchTerms/update-round';
export const DELETE_SEARCH_ROUND = '@searchTerms/delete-round';
export const ADD_SEARCH_TERM = '@searchTerms/add-term';
export const UPDATE_SEARCH_TERM = '@searchTerms/update-term';
export const DELETE_SEARCH_TERM = '@searchTerms/delete-term';
export const SET_SEARCH_LOADING = '@searchTerms/set-loading';
export const SEARCH_TERMS_FAILURE = '@searchTerms/set-failure';

export const getSearchRoundParams = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SEARCH_LOADING,
      payload: {isLoading: true, category: params.category},
    });
    const response = await axios.post(`${API_URL}/api/term/getterms`, params);
    if (response.status === 200 && response.data.terms) {
      dispatch({
        type: GET_SEARCH_PARAMS,
        payload: {rounds: response.data.terms, category: params.category},
      });
    }
  } catch (error) {
    dispatch({
      type: SEARCH_TERMS_FAILURE,
      payload: {category: params.category},
    });
    throw error;
  }
};

export function addSearchRound(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_LOADING,
        payload: {isLoading: true, category: data.category},
      });
      const response = await axios.post(`${API_URL}/api/term/addround`, data);
      if (response.status === 200 && response.data.terms) {
        dispatch({
          type: ADD_SEARCH_ROUND,
          payload: {
            rounds: response.data.terms,
            category: data.category,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_TERMS_FAILURE,
        payload: {category: data.category},
      });
      throw error;
    }
  };
}

export function updateSearchRound(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_LOADING,
        payload: {isLoading: true, category: data.category},
      });
      const response = await axios.post(`${API_URL}/api/term/updateround`, data);
      if (response.status === 200 && response.data.terms) {
        dispatch({
          type: UPDATE_SEARCH_ROUND,
          payload: {
            rounds: response.data.terms,
            category: data.category,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_TERMS_FAILURE,
        payload: {category: data.category},
      });
      throw error;
    }
  };
}

export function deleteSearchRound(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_LOADING,
        payload: {isLoading: true, category: data.category},
      });
      const response = await axios.post(`${API_URL}/api/term/deleteround`, data);
      if (response.status === 200 && response.data.terms) {
        dispatch({
          type: DELETE_SEARCH_ROUND,
          payload: {
            rounds: response.data.terms,
            category: data.category,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_TERMS_FAILURE,
        payload: {category: data.category},
      });
      throw error;
    }
  };
}

export function addSearchTerm(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_LOADING,
        payload: {isLoading: true, category: data.category},
      });
      const response = await axios.post(`${API_URL}/api/term/addterm`, data);
      if (response.status === 200) {
        const {terms, updatedRound} = response.data;
        dispatch({
          type: ADD_SEARCH_TERM,
          payload: {
            searchRounds: terms,
            updatedRoundId: updatedRound,
            category: data.category,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_TERMS_FAILURE,
        payload: {category: data.category},
      });
      throw error;
    }
  };
}

export function updateSearchTerm(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_LOADING,
        payload: {isLoading: true, category: data.category},
      });
      const response = await axios.post(`${API_URL}/api/term/updateterm`, data);
      if (response.status === 200) {
        const {terms, updatedRound} = response.data;
        dispatch({
          type: UPDATE_SEARCH_TERM,
          payload: {
            searchRounds: terms,
            updatedRoundId: updatedRound,
            category: data.category,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_TERMS_FAILURE,
        payload: {category: data.category},
      });
      throw error;
    }
  };
}

export function deleteSearchTerm(data) {
  return async(dispatch) => {
    try {
      dispatch({
        type: SET_SEARCH_LOADING,
        payload: {isLoading: true, category: data.category},
      });
      const response = await axios.post(`${API_URL}/api/term/deleteterm`, data);
      if (response.status === 200) {
        const {terms, updatedRound} = response.data;
        dispatch({
          type: DELETE_SEARCH_TERM,
          payload: {
            searchRounds: terms,
            updatedRoundId: updatedRound,
            category: data.category,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_TERMS_FAILURE,
        payload: {category: data.category},
      });
      throw error;
    }
  };
}

export const setSearchParamsLoading = (isLoading = true, category) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_LOADING,
    payload: {isLoading, category},
  });
};