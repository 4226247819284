import { combineReducers } from 'redux'
import calenderReducer from './calendar/'
import emailReducer from './email/'
import chatReducer from './chat/'
import todoReducer from './todo/'
import customizer from './customizer/'
import auth from './auth/'
import navbar from './navbar/Index'
import dataList from './data-list/'

import accountReducer from './accountReducer'
import companyReducer from './companyReducer'
import currencyReducer from './currencytReducer'
import usersReducer from './usersReducer'
import categoryReducer from './categoryReducer'
import productReducer from './productReducer'
import feeReducer from './feeReducer'
import orderReducer from './orderReducer'
import customSearchReducer from './customSearchReducer'
import preOrderReducer from './preOrderReducer'
import searchTermsReducer from './searchTermsReducer'
import epOrderReducer from './epOrderReducer'

const appReducer = combineReducers({
  account: accountReducer,
  company: companyReducer,
  currency: currencyReducer,
  users: usersReducer,
  category: categoryReducer,
  products: productReducer,
  fees: feeReducer,
  orders: orderReducer,
  customSearch: customSearchReducer,
  preOrders: preOrderReducer,
  searchTerms: searchTermsReducer,
  epOrders: epOrderReducer,

  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList
});

// Root reducer to handle logout and reset state
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined; // Reset the state to initial on logout
  }
  return appReducer(state, action);
};

export default rootReducer
