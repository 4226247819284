import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_ORDER = '@order/create-order';
export const UPDATE_ORDER = '@order/update-order';
export const GET_ORDERS = '@order/list-order';
export const GET_ORDER_HISTORY = '@order/list-order-history';
export const RESET_ORDERS = '@order/reset-order';
export const DELETE_ORDER = '@order/delete-order';
export const DELETE_ORDERS = '@order/delete-orders';
export const ORDER_FAILURE = '@account/order-failure';
export const SET_PAYMENT = '@account/set-payment';
export const SET_ORDER_LOADING = '@order/set-loading';

export function getOrders() {

  const request = axios.get(API_URL + '/api/orders')

  return (dispatch) => {
    dispatch({
      type: SET_ORDER_LOADING,
      payload: true,
    });
    request.then((response) => dispatch({
      type: GET_ORDERS,
      payload: response.data
    }));
  };
}

export function getOrderHistory(params) {
  let request = axios.get(`${API_URL}/api/orders/orderhistory/list`);
  if (params) {
    request = axios.post(`${API_URL}/api/orders/orderhistory/list`, params);
  }
  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_ORDER_HISTORY,
      payload: response.data
    }));
  };
}

export function resetOrders() {
  return (dispatch) => {
   dispatch({
      type: RESET_ORDERS,
      payload: null
    });
  };
}

export function getOrdersByDate({ fromdate, todate }) {
  return async(dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/api/orders/all`, { fromdate, todate });
      dispatch({
        type: GET_ORDERS,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
};

export function createOrder(order) {

  return async(dispatch) => {
    try {
      let formData = new FormData();
      let subjectName = order.subjectName === '' ? ' ' : order.subjectName
      let referenceId = order.referenceId === '' ? ' ' : order.referenceId
      // let bulkFileName = order.bulkFileName === '' ? ' ' : order.bulkFileName
      // let singleFileName = order.singleFileName === '' ? ' ' : order.singleFileName
      let relevantInfo = order.relevantInfo === '' ? ' ' : order.relevantInfo

      let singleFileNameList = [];
      if(order.singleFileData) {
        order.singleFileData.forEach(item => {
          formData.append('singlefiledata', item);
          singleFileNameList.push(item.name);
        })
      }
      formData.append('singleFileNameList', JSON.stringify(singleFileNameList));

      let bulkFileNameList = [];
      if(order.bulkFileData) {
        order.bulkFileData.forEach(item => {
          formData.append('bulkfiledata', item);
          bulkFileNameList.push(item.name);
        })
      }
      formData.append('bulkFileNameList', JSON.stringify(bulkFileNameList));

      formData.append('userId', order.userId)
      formData.append('userName', order.userName)
      formData.append('orderNo', order.orderNo);
      formData.append('orderType', order.orderType);
      formData.append('fee', order.fee);
      formData.append('feeOption', order.feeOption);
      formData.append('iMapFeeOption', order.iMapFeeOption);
      formData.append('feeOptionLabel', order.feeOptionLabel);
      formData.append('iMapFeeOptionLabel', order.iMapFeeOptionLabel);
      formData.append('currencyFeeoptionLabel', order.currencyFeeoptionLabel);
      formData.append('iMapCurrencyFeeoptionLabel', order.iMapCurrencyFeeoptionLabel);
      formData.append('serviceId', order.serviceId);
      formData.append('type', order.type);
      formData.append('subjectName', subjectName);
      formData.append('referenceId', referenceId);
      formData.append('country', order.country);
      formData.append('countryItem', order.countryItem);
      formData.append('countryItemDisplay', order.countryItemDisplay);
      formData.append('jurisdiction', order.jurisdiction);
      formData.append('subjectNum', order.subjectNum);
      formData.append('orderStatus', order.orderStatus);
      formData.append('relevantInfo', relevantInfo);
      formData.append('instruction', order.instruction);
      formData.append('currencyFee', order.currencyFee);
      formData.append('currencyFeeOption', order.currencyFeeOption);
      formData.append('iMapCurrencyFeeOption', order.iMapCurrencyFeeOption);

      const res = await axios.post(API_URL + `/api/orders`, formData);

      dispatch({
        type: CREATE_ORDER,
        payload: res
      });
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
}

export function updateOrder(update) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORDER_LOADING,
        payload: true,
      });
      const response = await axios.put(`${API_URL}/api/orders`, update);
      dispatch({
        type: UPDATE_ORDER,
        payload: response.data,
      })
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
}

export function deleteOrder(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORDER_LOADING,
        payload: true,
      });
      const response = await axios.delete(`${API_URL}/api/orders/${id}`);
      dispatch({
        type: DELETE_ORDER,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
}

export function deleteOrders(ids) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORDER_LOADING,
        payload: true,
      });
      const response = await axios.post(`${API_URL}/api/orders/bulk-delete`, {ids});
      dispatch({
        type: DELETE_ORDERS,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
}

export function setPayment(payInfo) {
  return async(dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/api/orders/pay`, payInfo);
      dispatch({
        type: SET_PAYMENT,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
};

export function uploadOrderFile(order, orderId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORDER_LOADING,
        payload: true,
      });
      const response = await axios.post(`${API_URL}/api/orders/upload/${orderId}`, order);
      dispatch({
        type: UPDATE_ORDER,
        payload: response.data
      });
    } catch (error) {
      dispatch({ type: ORDER_FAILURE });
      throw error;
    }
  };
    // try { 
    //     const res = await axios.post(APU_URL + `/api/orders/upload/${orderId}`, order, config);
    //     dispatch({ type: ORDER_SAVED, payload: res.data });
    //     getOrders()
    // } catch (err) {
    //     dispatch({ type: ORDER_ERROR, payload: err });
    // }
}