import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_CATEGORY = '@category/create-category';
export const UPDATE_CATEGORY = '@category/update-category';
export const GET_CATEGORIES = '@category/list-category';
export const DELETE_CATEGORY = '@category/delete-category';

export function getCategories() {

    const request = axios.get(`${API_URL}/api/categories`)

    return (dispatch) => {
        request.then((response) => dispatch({
            type: GET_CATEGORIES,
            payload: response.data
        }));
    }; 
}

export function createCategory(category) {

    const request = axios.post(`${API_URL}/api/categories`, category);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: CREATE_CATEGORY,
            payload: response.data
        }));
    };
}

export function updateCategory(update) {
    const request = axios.put(`${API_URL}/api/categories/${update._id}`, update);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_CATEGORY,
            payload: response.data
        }));
    };
} 

export function updateCategoryCount(update) {
    const request = axios.put(`${API_URL}/api/categories/count/${update}`);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_CATEGORY,
            payload: response.data
        }));
    };
} 

export function deleteCategory(category) {
    const request = axios.delete(`${API_URL}/api/categories/${category._id}`);
  
    return (dispatch) => {
      request.then((response) => dispatch({
        type: DELETE_CATEGORY,
        payload: category
      }));
    };
  } 