import React from 'react';
import * as Icon from 'react-feather';
import { getItemFromStorage } from 'src/utils/utils';

const preOrderId = getItemFromStorage('preorderid');

const navigationConfigPreorder = [
  {
    type: 'groupHeader',
    groupTitle: 'EMEPMI Orders'
  },
  {
    id: 'customerPreorders',
    title: 'EMEPMI Orders',
    type: 'collapse',
    icon: <Icon.Codepen size={20} />,
    children: [
      {
        id: 'candidateList',
        title: 'Candidate List',
        type: 'item',
        icon: <Icon.Circle size={12} />,
        navLink: `/customer/preorder/candidates?preorderid=${preOrderId}`,
      },
    ]
  },
];

export default navigationConfigPreorder;
