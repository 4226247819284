import React from 'react';
import { Navbar } from 'reactstrap';
import classnames from 'classnames';
import NavbarPreOrderCustomer from './NavbarPreOrderCustomer';
import userImg from "src/assets/img/logo/user-avatar2.png"
import { useIntl } from 'react-intl';

export default function PreOrderNavbar(props) {
  const intl = useIntl();
  const colorsArr = [ 'primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating' , 'static' , 'sticky' , 'hidden'];
  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light': props.navbarColor === 'default' || !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            'scrolling': props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-end align-items-center"
              id="navbar-mobile"
            >
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarPreOrderCustomer
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={intl.formatMessage({id: 'Customer'})}
                userImg={ userImg }
              />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  )
}
