/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_SEARCH_PARAMS,
  ADD_SEARCH_ROUND,
  UPDATE_SEARCH_ROUND,
  DELETE_SEARCH_ROUND,
  ADD_SEARCH_TERM,
  UPDATE_SEARCH_TERM,
  DELETE_SEARCH_TERM,
  SET_SEARCH_LOADING,
  SEARCH_TERMS_FAILURE,
} from 'src/redux/actions/searchTermsActions';
import { SearchParamsCategoryType } from 'src/utils/constants';

const initialState = {
  enSearchRounds: null,
  cnSearchRounds: null,
  searchRound: null,
  enUpdatedRoundId: null,
  cnUpdatedRoundId: null,
  isLoadingEnTerms: false,
  isLoadingCnTerms: false,
};

const searchTermsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_SEARCH_LOADING: {
      const { isLoading, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.isLoadingEnTerms = isLoading;
        } else {
          draft.isLoadingCnTerms = isLoading;
        }
      })
    }

    case SEARCH_TERMS_FAILURE: {
      const { category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.isLoadingEnTerms = false;
        } else {
          draft.isLoadingCnTerms = false;
        }
      })
    }

    case GET_SEARCH_PARAMS: {
      const { rounds, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = rounds;
          draft.isLoadingEnTerms = false;
          draft.enUpdatedRoundId = null;
        } else {
          draft.cnSearchRounds = rounds;
          draft.isLoadingCnTerms = false;
          draft.cnUpdatedRoundId = null;
        }
        draft.searchRound = null;
      })
    }

    case ADD_SEARCH_ROUND: {
      const { rounds, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = rounds;
          draft.isLoadingEnTerms = false;
        } else {
          draft.cnSearchRounds = rounds;
          draft.isLoadingCnTerms = false;
        }
      });
    }

    case UPDATE_SEARCH_ROUND: {
      const { rounds, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = rounds;
          draft.isLoadingEnTerms = false;
        } else {
          draft.cnSearchRounds = rounds;
          draft.isLoadingCnTerms = false;
        }
      });
    }

    case DELETE_SEARCH_ROUND: {
      const { rounds, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = rounds;
          draft.isLoadingEnTerms = false;
        } else {
          draft.cnSearchRounds = rounds;
          draft.isLoadingCnTerms = false;
        }
      });
    }

    case ADD_SEARCH_TERM: {
      const { searchRounds, updatedRoundId, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = searchRounds;
          draft.enUpdatedRoundId = updatedRoundId;
          draft.isLoadingEnTerms = false;
        } else {
          draft.cnSearchRounds = searchRounds;
          draft.cnUpdatedRoundId = updatedRoundId;
          draft.isLoadingCnTerms = false;
        }
      });
    }

    case UPDATE_SEARCH_TERM: {
      const { searchRounds, updatedRoundId, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = searchRounds;
          draft.isLoadingEnTerms = false;
          draft.enUpdatedRoundId = updatedRoundId;
        } else {
          draft.cnSearchRounds = searchRounds;
          draft.isLoadingCnTerms = false;
          draft.cnUpdatedRoundId = updatedRoundId;
        }
      });
    }

    case DELETE_SEARCH_TERM: {
      const { searchRounds, updatedRoundId, category } = action.payload;
      return produce(state, (draft) => {
        if (category === SearchParamsCategoryType.ENGLISH) {
          draft.enSearchRounds = searchRounds;
          draft.isLoadingEnTerms = false;
          draft.enUpdatedRoundId = updatedRoundId;
        } else {
          draft.cnSearchRounds = searchRounds;
          draft.isLoadingCnTerms = false;
          draft.cnUpdatedRoundId = updatedRoundId;
        }
      });
    }
    
    default: {
      return state;
    }
  }
};

export default searchTermsReducer;
