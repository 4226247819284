import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_USER = '@users/create-user';
export const DELETE_USER = '@users/delete-user';
export const UPDATE_USER = '@users/update-user';
export const GET_USERS = '@users/list-users';
export const RESET_USERS = '@users/reset-users';

export const getUsers = (requestParams = null) => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/api/users`);
    if (response.status === 200) {
      dispatch({
        type: GET_USERS,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

  export const resetUsers = () => async (dispatch) => {
    dispatch({
      type: RESET_USERS,
      payload: null,
    });
  };

export const createUser = (user) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}/api/users`, user);
    if (response.status === 200) {
      dispatch({
        type: CREATE_USER,
        payload: response.data
      });
    }
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const updateUser = (update) => async (dispatch) => {
  try {
    const response = await axios.put(`${API_URL}/api/users/${update._id}`, update);
    if (response.status === 200) {
      dispatch({
        type: UPDATE_USER,
        payload: response.data
      });
    }
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export function deleteUser(user) {
  const request = axios.delete(`${API_URL}/api/users/${user._id}`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: DELETE_USER,
      payload: user
    }));
  };
};
