import React from 'react'
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import { logout } from 'src/redux/actions/accountActions';
import ReactCountryFlag from "react-country-flag"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import { FormattedMessage, useIntl } from 'react-intl';

const REACT_APP_ENV = process.env.REACT_APP_ENV;

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/login');
    } catch (error) {
      enqueueSnackbar(intl?.formatMessage({id: 'Unable to logout'}), {
        variant: 'error'
      });
    }
  };
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/app/profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Edit Profile"/></span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={e => {
          e.preventDefault()
          handleLogout();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Log Out"/></span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    shoppingCart: [],
    suggestions: []
  }

  constructor(props) {
    super(props);
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {
    axios.get('/api/main-search/data', {
      cancelToken: this.source.token
    }).then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    }).catch((error) => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        // handle other errors
        console.error(error);
      }
    });
  }

  componentWillUnmount() {
    this.source.cancel('Component unmounted, request canceled.');
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {REACT_APP_ENV !== 'production' && (
          <IntlContext.Consumer>
            {context => {
              let langArr = {
                'en': 'English',
                'de': 'German',
                'fr': 'French',
                'pt': 'Portuguese',
                'zh': '中文',
              }
              return (
                <Dropdown
                  tag="li"
                  className="dropdown-language nav-item"
                  isOpen={this.state.langDropdown}
                  toggle={this.handleLangDropdown}
                  data-tour="language"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link"
                  >
                    <ReactCountryFlag
                    className="country-flag"
                      countryCode={
                        context.locale === 'en'
                          ? 'us'
                          : context.locale === 'zh'
                          ? 'cn'
                          : context.locale
                      }
                      svg
                    />
                    <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                      {langArr[context.locale]}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag="a"
                      onClick={e => context.switchLanguage("en")}
                    >
                      <ReactCountryFlag className="country-flag" countryCode="us" svg />
                      <span className="ml-1">English</span>
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      onClick={e => context.switchLanguage('zh')}
                    >
                      <ReactCountryFlag className="country-flag" countryCode="cn" svg />
                      <span className="ml-1">中文</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )
            }}
          </IntlContext.Consumer>
        )}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600 mb-0">
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user" className="round bg-warning">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
