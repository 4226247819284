/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_CURRENCY,
  UPDATE_CURRENCY,
  GET_CURRENCIES,
  GET_CURRENCYLABELS
} from 'src/redux/actions/currencyActions';

const initialState = {
  currencies: null,
  currencyLabels: null,
  newCurrency: null,
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCIES: {
      const currencies = action.payload
      return produce(state, (draft) => {
        draft.currencies = currencies
      });
    }
    case GET_CURRENCYLABELS: {
      const currencyLabels = action.payload
      return produce(state, (draft) => {
        draft.currencyLabels = currencyLabels
      });
    }
    case CREATE_CURRENCY: {
      const newCurrency = action.payload
      return produce(state, (draft) => {
        draft.newCurrency = newCurrency
      });
    }
    case UPDATE_CURRENCY: {
      const newCurrency = action.payload
      return produce(state, (draft) => {
        draft.newCurrency = newCurrency
      });
    }

    default: {
      return state;
    }
  }
};

export default currencyReducer;
