/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    GET_USERS,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    RESET_USERS
} from 'src/redux/actions/usersActions';

const initialState = {
    users: null,
    user: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) { 

    case GET_USERS: {
      const users = action.payload
      return produce(state, (draft) => {
        draft.users = users
        draft.user = null
      })
    }

    case CREATE_USER: {
      const user = action.payload;
      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_USER: {
      const user = action.payload;
      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case DELETE_USER: {
      const user = action.payload;
      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case RESET_USERS: {
      return produce(state, (draft) => {
        draft.users = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default usersReducer;
