import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import logo from 'src/assets/img/logo/linq-logo.png';
import NavbarHomeGuest from './NavbarHomeGuest';
import { FormattedMessage } from 'react-intl';
import { IntlContext } from 'src/utility/context/Internationalization';
import { Menu, X } from 'react-feather';
import { useScroll } from 'src/context/ScrollContext';
import ReactCountryFlag from 'react-country-flag';

export default function HomeNavbar (props) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const collapseRef = useRef(null); // To track the Collapse element
  const REACT_APP_ENV = process.env.REACT_APP_ENV;

  const [langDropdown, setLangDropDown] = useState(false);

  const handleLangDropdown = () => {
    setLangDropDown(!langDropdown);
  };

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
  const history = useHistory();
  const location = useLocation();
  const { scrollToSection } = useScroll();

  const colorsArr = [ 'primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating' , 'static' , 'sticky' , 'hidden'];

  // Conditionally render the NavbarToggler based on screen width (e.g., 992px as breakpoint)
  const showNavbarToggler = screenWidth < 768;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleClickOurService = () => {
    if (location.pathname === '/home') {
      scrollToSection('homeServices');
    } else {
      history.push('/home?section=homeServices');
      // scrollToSection('homeServices');
    }
  };

  // Listen for screen size changes
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close the navbar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (collapseRef.current && !collapseRef.current.contains(event.target) && isMobileMenuOpen) {
        setMobileMenuOpen(false); // Close the Collapse
      }
    };

    // Add event listener to detect clicks outside the navbar
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        id="home-navbar"
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light': props.navbarColor === 'default' || !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            'scrolling': props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="logo d-flex align-items-center">
              {showNavbarToggler ? (
                <img
                  src={logo}
                  alt="logo"
                  className="cursor-pointer"
                  style={{ height: '45px', width: 'auto'}} 
                  onClick={() => history.push('/home')}
                />
              ) : (
                <img
                  src={logo}
                  alt="logo"
                  className="cursor-pointer"
                  style={{ height: '60px', width: 'auto'}} 
                  onClick={() => history.push('/home')}
                />
              )}
              </div>
               {/* Conditionally display the NavbarToggler based on screen size */}
               {showNavbarToggler && (
                <div className="d-flex">
                  {REACT_APP_ENV !== 'production' && (
                    <IntlContext.Consumer>
                      {context => {
                        let langArr = {
                          'en' : 'English',
                          'zh': '中文',
                        }
                        return (
                          <Dropdown
                            // tag="li"
                            className="dropdown-language nav-item"
                            isOpen={langDropdown}
                            toggle={handleLangDropdown}
                            data-tour="language"
                          >
                            <DropdownToggle
                              color="info"
                              tag="a"
                              className="nav-link py-0 d-flex w-100 h-100"
                            >
                              <Button
                                className="px-50 py-50 d-flex align-items-center font-weight-bold font-11"
                                color="flat-info"
                              >
                                <ReactCountryFlag
                                className="country-flag"
                                  countryCode={
                                    context.locale === 'en'
                                      ? 'us'
                                      : context.locale === 'zh'
                                      ? 'cn'
                                      : context.locale
                                  }
                                  style={{
                                    width: '25px',
                                    height: 'auto'
                                  }}
                                  svg
                                />
                                <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                                  {langArr[context.locale]}
                                </span>
                              </Button>
                            </DropdownToggle>
                            <DropdownMenu right className="mt-0 ">
                              <DropdownItem
                                tag="a"
                                onClick={e => context.switchLanguage('en')}
                              >
                                <ReactCountryFlag className="country-flag" countryCode="us" svg />
                                <span className="ml-1">English</span>
                              </DropdownItem>
                              <DropdownItem
                                tag="a"
                                onClick={e => context.switchLanguage('zh')}
                              >
                                <ReactCountryFlag className="country-flag" countryCode="cn" svg />
                                <span className="ml-1">中文</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )
                      }}
                    </IntlContext.Consumer>
                  )}
                  <NavbarToggler onClick={toggleMobileMenu} className="d-flex">
                    {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />} {/* Conditionally render the icon */}
                  </NavbarToggler>
                </div>
               )}
              <div className="d-none d-md-flex d-lg-flex">
                <NavbarHomeGuest
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                />
              </div>
            </div>
            {/* Mobile Menu */}
            {showNavbarToggler && (
              <Collapse isOpen={isMobileMenuOpen} navbar className="d-md-none d-lg-none pt-1" innerRef={collapseRef}>
                <Nav vertical className="w-100 text-center">
                  <NavItem>
                    <NavLink className="py-50">
                      <Button
                        className="px-3 w-100"
                        color={location?.pathname === '/home' ? 'info' : 'flat-info'}
                        onClick={() => history.push('/home')}
                      >
                        <FormattedMessage id="Home" />
                      </Button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="py-50">
                      <Button
                        className="px-3 w-100"
                        color={'flat-info'}
                        onClick={handleClickOurService}
                      >
                        <FormattedMessage id="KYC & Due Diligence"/>
                      </Button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="py-50">
                      <Button
                        className="px-3 w-100"
                        color={location?.pathname === '/ep-assure' ? 'info' : 'flat-info'}
                        onClick={() => history.push('/ep-assure')}
                      >
                        <FormattedMessage id="EP-ASSURE"/>
                      </Button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="py-50">
                      <Button
                        className="px-3 w-100"
                        color={location?.pathname === '/contact-us' ? 'info' : 'flat-info'}
                        onClick={() => history.push('/contact-us')}
                      >
                        <FormattedMessage id="Contact Us" />
                      </Button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="py-50">
                      <Button
                        className="px-3 w-100"
                        color={location?.pathname === '/register' ? 'info' : 'flat-info'}
                        onClick={() => history.push('/register')}
                      >
                        <FormattedMessage id="Register" />
                      </Button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="py-50">
                      <Button
                        className="px-3 w-100"
                        color={location?.pathname === '/login' ? 'info' : 'flat-info'}
                        onClick={() => history.push('/login')}
                      >
                        <FormattedMessage id="Login" />
                      </Button>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            )}
          </div>
        </div>
      </Navbar>
    </>
  )
}
