import React, { useState, useEffect, createContext } from 'react';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-numberformat/locale-data/zh'; // Simplified Chinese
import '@formatjs/intl-numberformat/locale-data/zh-Hant'; // Traditional Chinese

import messages_en from 'src/assets/data/locales/en.json';
import messages_de from 'src/assets/data/locales/de.json';
import messages_fr from 'src/assets/data/locales/fr.json';
import messages_pt from 'src/assets/data/locales/pt.json';
import messages_zh from 'src/assets/data/locales/zh.json';

const menu_messages = {
  en: messages_en,
  de: messages_de,
  fr: messages_fr,
  pt: messages_pt,
  zh: messages_zh,
};

const IntlContext = createContext();

const getBrowserLanguage = () => {
  // Get the user's browser language
  const browserLanguage = navigator.language || navigator.userLanguage;
  console.log(browserLanguage);

  // Map to the supported languages, default to 'en' if not found
  const supportedLanguages = ['en', 'de', 'fr', 'pt', 'zh'];
  const languagePrefix = browserLanguage.split('-')[0]; // Take only the language code (e.g., "en" from "en-US")

  return supportedLanguages.includes(languagePrefix) ? languagePrefix : 'en';
};

const IntlProviderWrapper = ({ children }) => {
  // Get the saved language from localStorage, or default to 'en'
  const storedLocale = localStorage.getItem('appLanguage') || getBrowserLanguage();
  // Set initial state for locale and messages
  const [locale, setLocale] = useState(storedLocale);
  const [messages, setMessages] = useState(menu_messages[storedLocale]);

  // Handle language switch and save to localStorage
  const switchLanguage = (language) => {
    setLocale(language);
    setMessages(menu_messages[language]);
    localStorage.setItem('appLanguage', language); // Save the selected language to localStorage
  };

  useEffect(() => {
    // Whenever the locale changes, update the messages state
    setMessages(menu_messages[locale]);
  }, [locale]);

  return (
    <IntlContext.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale='en'
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

export { IntlProviderWrapper, IntlContext }
